import React from 'react'
import Content from '../../components/Content'
import PageTitle from '../../components/PageTitle'
import Summary from '../../components/Summary'
import Lendstats from '../../components/Lendstats'

export default function History() {
  return (
    <Content page={'history'}>
      <PageTitle title={'History'} />
      <Lendstats />
      <Summary />
    </Content>
  )
}