import React from 'react'
import { Card, Col, Image, Row } from 'react-bootstrap'
import { Image as Cloudinary } from 'cloudinary-react'
import blankAvatar from '../../../assets/blank-avatar.png'
import moment from 'moment'

export default function TransactionCard(props) {
  const { avatar, data, index } = props
  if (!data.notification.transfer) {
	  return false;
  }
  const hasNotification = data.notification.transfer?.status === 0 && data.handleModal
  const isDown = data.notification.transfer.down
  const isSender = data.notification.transfer.sender === data.userId
  const isInitiated = data.notification.transfer.initiatedBy === data.userId
  const notificationTime = moment(data.notification.initiated).format('hh:mm A')

  return (
    <Card className={data.notification.transfer?.status === 0 ? 'offer-request' : ''} onClick={() => {hasNotification && data.handleModal(index)}}>
      <Row className={'pl-3 pr-3'}>
        <Col xs={'3'} className={'card__avatar user-avatar-md p-0'}>
          {avatar ?
            <Cloudinary
              cloudName={'dvfifodns'}
              publicId={avatar.file_name}
              width={40}
              height={40}
              crop={'thumb'}
              gravity={'face'}
            />           
          :
            <Image roundedCircle src={blankAvatar} />
          }
        </Col>
        <Col xs={'9'} className={`card__text ${hasNotification ? 'card__text--bold card__text--cursor' : ''} p-0`}>
          <small>{notificationTime}</small>
          <small className={'blue'}>{data.notification.transfer.notes}</small>
          {isDown && isInitiated && isSender && (
            <p><b>You</b> offered to sell {data.notification.transfer.notes} to <b>{data.notification.receiver.first_name} {data.notification.receiver.last_name}</b> for <b className={'blue'}><span className={'transaction-add'}>${data.notification.transfer.amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span></b>.</p>
          )}
          {isDown && !isInitiated && !isSender && (
            <p><b>{data.notification.sender.first_name}</b> offered to sell <b>you</b> {data.notification.transfer.notes} for <b className={'blue'}><span className={'transaction-add'}>${data.notification.transfer.amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span> by  {data.notification.sender.last_name}</b>.</p>
          )}
          {isDown && !isInitiated && isSender && (
            <p><b>{data.notification.receiver.first_name} {data.notification.receiver.last_name}</b> requested to purchase {data.notification.transfer.notes} from <b>you</b> for <b className={'blue'}><span className={'transaction-add'}>${data.notification.transfer.amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span></b>.</p>
          )}
          {isDown && isInitiated && !isSender && (
            <p><b>You</b> offered to purchase {data.notification.transfer.notes} from <b>{data.notification.sender.first_name} {data.notification.sender.last_name}</b> for <b className={'blue'}><span className={'transaction-subtract'}>${data.notification.transfer.amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span></b>.</p>
          )}
          {!isDown && isInitiated && isSender && (
            <p><b>You</b> offered <b>{data.notification.receiver.first_name}  {data.notification.receiver.last_name}</b> <b className={'blue'}><span className={'transaction-subtract'}>${data.notification.transfer.amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span></b>.</p>
          )}
          {!isDown && !isInitiated && !isSender && (
            <p><b>You</b> were offered <b className={'blue'}><span className={'transaction-add'}>${data.notification.transfer.amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span></b> by <b>{data.notification.sender.first_name} {data.notification.sender.last_name}</b>.</p>
          )}
          {!isDown && !isInitiated && isSender && (
            <p><b>{data.notification.receiver.first_name} {data.notification.receiver.last_name}</b> requested <b className={'blue'}><span className={'transaction-subtract'}>${data.notification.transfer.amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span></b>.</p>
          )}
          {!isDown && isInitiated && !isSender && (
            <p><b>You</b> requested <b className={'blue'}><span className={'transaction-add'}>${data.notification.transfer.amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span></b> from <b>{data.notification.sender.first_name} {data.notification.sender.last_name}</b>.</p>
          )}
        </Col>
      </Row>
    </Card>
  )
}