import * as React from 'react'
import { Card, Col, Image, ProgressBar, Row } from 'react-bootstrap'
import { Image as Cloudinary } from 'cloudinary-react'
import blankAvatar from '../../../assets/blank-avatar.png'
import moment from 'moment'
import axios from 'axios'

export default function PaymentCard(props) {
  const { avatar, data } = props
  const [paymentCompletion, setPaymentCompletion] = React.useState(0);

  React.useEffect(() => {
    axios.get(`/api/payments/transaction/${data.notification.transaction_id}`)
      .then((response) => {
        const totalLength = response.data.length;
        const paid = response.data.filter((dataItem) => dataItem.status === 1).length
        setPaymentCompletion(Number(paid) / Number(totalLength) * 100)
      })
  }, [])

  return (
    <Card className={'card'}>
      <Row className={'pl-3 pr-3'}>
        <Col xs={'3'} className={'card__avatar user-avatar-md p-0'}>
          {avatar ?
            <Cloudinary
              cloudName={'dvfifodns'}
              publicId={avatar.file_name}
              width={40}
              height={40}
              crop={'thumb'}
              gravity={'face'}
            />           
          :
            <Image roundedCircle src={blankAvatar} />
          }
        </Col>
        <Col xs={'9'} className={'card__text p-0'}>
          <small className={'orange'}>Upcoming Payment</small>
          <p className={'m-0'}>Your payment of <b className={'blue'}>${(Number(data.notification.payment) * 0.0125).toFixed(2) > 12.5 ? (Number(data.notification.payment) + (12.5)).toFixed(2) : ((Number(data.notification.payment) + Number(data.notification.payment) * 0.0125)).toFixed(2)}</b> to <b>{data.notification.receiver.first_name} {data.notification.receiver.last_name}</b> will be drafted on {moment(data.notification.date).format('MM/DD/YYYY')}.</p>
        </Col>
        <Col xs="12">
          <small>Payment progress on this loan:</small>
          <ProgressBar variant="success" now={paymentCompletion} label={`${paymentCompletion.toFixed(1)}%`} />
        </Col>
      </Row>
    </Card>     
  )
}