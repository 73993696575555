import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Col, Container } from 'react-bootstrap'
import axios from 'axios'
import Swal from 'sweetalert2'
import User from '../../helpers/User'
import { store } from '../../helpers/Store'
import { Step1, Step2, Step3 } from './Steps'
import './styles.scss'

export default function LendBorrow({ transactionView }) {
  const history = useHistory()
  const globalState = useContext(store)
  const { state, dispatch } = globalState
  const transactionFriendId = state['transactionFriendId']

  const [step, setStep] = useState(1)
  const [loading, setLoading] = useState(false)
  const [newLoan, setNewLoan] = useState({})
  const [payment, setPayment] = useState(Number((0).toFixed(2)))
  const [fees, setFees] = useState(0)
  const [total, setTotal] = useState(0)
  const [term, setTerm] = useState(0)
  const [graceperiodchecked, setgracePeriodChecked] = useState(0)
  const [loan_fee, setloanfee] = useState(0)
  const [enableloanfee, setEnableloanfee] = useState(0)
  const [gracePeriod, setgracePeriod] = useState(0)
  const [view, setView] = useState(0)
  const [paymentMethods, setPaymentMethods] = useState([])
  const [debitCards, setDebitCards] = useState([])
  const [friends, setFriends] = useState([])
  const [sender, setSender] = useState(transactionView === 'borrow' ? transactionFriendId : '')
  const [receiver, setReceiver] = useState(transactionView === 'lend' ? transactionFriendId : '')
  const userData = new User().getData()
  const userId = userData.user.id
  const [transDetail, setTransDetail] = useState({});

  useEffect(() => {
    if (debitCards.length > 0) {
      setPaymentMethods([
        ...debitCards,
        ...paymentMethods
      ])
    }
  }, [debitCards, setPaymentMethods])

  useEffect(() => {
    const controller = new AbortController()

    const fetchData = async() => {
      const fundingRes = await axios.get(`/api/funding-source/${userId}`)
      const debitCardsRes = await axios.get(`/api/debit-cards/${userId}`)
      const friendsRes = await axios.get(`/api/friends/${userId}`)
	
      setPaymentMethods(fundingRes.data)
      setDebitCards(debitCardsRes.data)
      setFriends(friendsRes.data)
    }
	getTransDetail();

    if(userId)
      fetchData()

    return () => {
			controller.abort()
		}
  }, [userId])

  useEffect(() => {
    setNewLoan({
      ...newLoan,
      ['total-mask']: `$ ${total.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
      ['fees-mask']:  `$ ${fees.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
    })
  }, [total, fees])
  useEffect(() => {
   

    const amount = Number(newLoan.amount)
    const payment = Number(newLoan.payment)
	if (newLoan.rate==undefined) {
		newLoan.rate = 1;
	}
	const rate = newLoan.rate / 100 / 12;
	
    //const newTotal = Number((amount * 0.0125).toFixed(2) > 12.5 ? (amount + 12.5).toFixed(2) : (amount * 1.0125).toFixed(2))
	let newTotal = null;
	if (JSON.stringify(transDetail) !== JSON.stringify({})) {
		
		if (transDetail.transaction_count < 3 && transDetail.is_subscribed == '0') {
			 newTotal = Number(
				  (amount * 0.015).toFixed(2) > 12.5
					? (amount + 12.5).toFixed(2)
					: (amount * 1.015).toFixed(2), // pari fee 1.5% alternative formula amount+ (amount*1.5%)
				);
		}else if (transDetail.transaction_count < 15 &&transDetail.is_subscribed == '1') {
			newTotal = Number(
			  (amount * 0.01).toFixed(2) > 12.5
				? (amount + 12.5).toFixed(2)
				: (amount * 1.01).toFixed(2), // pari fee 1%
			);
		} else {
			newTotal = Number(
			  (amount * 0.005).toFixed(2) > 12.5
				? (amount + 12.5).toFixed(2)
				: (amount * 1.005).toFixed(2), // pari fee 0.5%
			);

		}	
		
	}  else {
		newTotal = Number(
			(amount * 0.015).toFixed(2) > 12.5
			  ? (amount + 12.5).toFixed(2)
			  : (amount * 1.015).toFixed(2),
		  );
    }
	
	if (enableloanfee && loan_fee>0) {
		newTotal = newTotal + Number(loan_fee);
		if (view === 0 && newTotal > 0 && newLoan.term >= 0) {
			
			let monthly = Number(newTotal / newLoan.term).toFixed(2);
			setPayment(parseFloat(monthly));
			
		} else if (view === 1 && newTotal > 0 && payment > 0) {
			let monthly = Number(newTotal / newLoan.payment).toFixed(2);
			setTerm(monthly);
		}
	}else {
      if (
        view === 0 && // view == 0 means by term and 1 means by payment
        newTotal > 0 &&
        newLoan.rate >= 0 &&
        newLoan.rate < 100 &&
        newLoan.term > 0
      ) {
        if (rate > 0 && rate < 100) {
          setPayment(
            Number(
              (
                (newTotal * rate * Math.pow(1 + rate, newLoan.term)) /
                (Math.pow(1 + rate, newLoan.term) - 1)
              ).toFixed(2),
            ),
          );
        } else {
          setPayment(Number((newTotal / newLoan.term).toFixed(2)));
        }
      } else if (
        view === 1 &&
        newTotal > 0 &&
        newLoan.rate >= 0 &&
        newLoan.rate < 100 &&
        payment > 0
      ) {
        if (rate > 0) {
          setTerm(
            Math.ceil(
              Number(
                (-1 * Math.log(1 - (rate * newTotal) / payment)) /
                  Math.log(1 + rate),
              ),
            ),
          );
        } else {
          setTerm(Math.ceil(Number(newTotal / payment)));
        }
      }
    }
	/* 
	if (view === 0 && newTotal > 0 && newLoan.rate >= 0 && newLoan.rate < 100 && newLoan.term > 0) {
      if (rate > 0 && rate < 100) {
        setPayment(Number((newTotal * rate * (Math.pow(1 + rate, newLoan.term)) / (Math.pow(1 + rate, newLoan.term) - 1)).toFixed(2)))
      } else {
        setPayment(Number((newTotal / newLoan.term).toFixed(2)))
      }
    } else if (view === 1 && newTotal > 0 && newLoan.rate >= 0 && newLoan.rate < 100 && payment > 0) {
      if (rate > 0) {
        setTerm(Math.ceil(Number((-1 * Math.log(1 - rate * newTotal / payment)) / (Math.log(1 + rate)))))
      } else {
        setTerm(Math.ceil(Number(newTotal / payment)))
      }
    } */
    setTotal(newTotal);
    setFees(newTotal - Number(newLoan.amount));
  }, [newLoan, view])

  const changeStep = (e, number) => {
    e.preventDefault()

    setStep(number)
  }
 const getTransDetail = () => {
    const body = {
       id: userId,
      //id: 'd8bd2947-c4fe-41e9-95d7-ae78c832986f',
    };
    const url = 'gettransaction/details';
    axios.post(`/api/gettransaction/details`, body)
      .then(res => {
        if (res.data.status === 'true') {
          setTransDetail(res.data.data);
        } else {
        }
      })
      .catch(error => {
      });
  };
  const handleChange = e => {
    const {name, value} = e.target
    
    setNewLoan({
      ...newLoan,
      [name]: name !== 'notes' ? Number(value) : value
    })
  }
  const graceperiodChange =(e)=> {
	 
	  if (e.target.checked==true) {
		setgracePeriodChecked(1);	
	  } else {
		  setgracePeriodChecked(0);
	  }
	  
  }
  
  const enableloanfeeChange =(e)=> {
	
	  if (e.target.checked==true) {
		setEnableloanfee(1);	
	  } else {
		  setEnableloanfee(0);
	  }
	  
  }
  const handleLoanfeeChange =(e, value, maskvalue)=> {
	 setloanfee(value);
	  
  }
  const handleViewgraceperiod =(e)=> {
    setgracePeriod(e.target.value);
	  
  }
  const handlePercentageChange = (name, value) => {
    if (Number(value) > 0 && Number(value) < 100) {
      setNewLoan({
        ...newLoan,
        [name]: Number(value)
      })
    } else {
      setNewLoan({
        ...newLoan,
        [name]: 0
      })
    }
  }

  const handleMoneyChange = (e, value, maskedValue) => {
    const { name } = e.target
    if (name === 'amount' && value > 5000) {
      setNewLoan({
        ...newLoan,
        [name]: 0,
        [`${name}-mask`]: 0
      })
    } else {
      setNewLoan({
        ...newLoan,
        [name]: value,
        [`${name}-mask`]: maskedValue
      })
    }
  }

  const handleViewChange = e => {
    e.persist()

    setView(Number(e.target.value))
  }

  const sendTransfer = async() => {
    setLoading(true)
	let gracePeriodvalue = 0;
	let loan_feevalue = 0;
	if (graceperiodchecked==1) {
		gracePeriodvalue = gracePeriod;
	}
	if (enableloanfee==1) {
		loan_feevalue = loan_fee;
		newLoan.rate = 0;
	} 
    const transfer = {
      amount: total,
      payment: view === 0 ? payment : newLoan.payment,
      grace_period: parseInt(gracePeriodvalue),
      loan_fee: parseFloat (loan_feevalue),
      rate: newLoan.rate,
      term: view === 0 ? newLoan.term : term,
      notes: newLoan.notes,
      method: paymentMethods[newLoan.method].dwollaPath,
      access_token: paymentMethods[newLoan.method].pAccessToken,
      sender: transactionView === 'lend' ? userId : sender,
      receiver: transactionView === 'borrow' ? userId : receiver,
      initiatedBy: userId,
      initiated: Date.now()
    }
    try{
      await axios.post('/api/transfers', transfer)

      dispatch({type: transactionView})
      dispatch({type: 'transactionFriendId', value: false})
      
      await Swal.fire({
        icon: 'success',
        title: 'Success!'
      })

      if(history.location.pathname === '/pari' || history.location.pathname === '/history')
        history.go(0)

      setLoading(false)
    }catch(err){
      await Swal.fire({
        icon: 'error',
        title: 'Error!',
        html: err.response.data
      })

      setLoading(false)
    }
  }

  const SelectedFriendName = () => {
    const friendId = transactionView === 'borrow' ? sender : receiver
    const friend = friends.find(x => x.user._id === friendId)
    
    return (
      <span>
        {friend.user.first_name} {friend.user.last_name}
      </span>
    )
  }

  return (
    <Container className={'lendborrow'}>
      <Col sm={'6'} className={'mx-auto pt-3 pb-4'}>
        <div className={'signup__header'}>
          <h1 className={'title'}>{transactionView === 'borrow' ? 'Borrow' : 'Lend'}</h1>
        </div>
        <div className={'signup__form'}>
          <div className={'signup__stepper'}>
            {step === 1 &&
              <Step1
                loan={newLoan}
                payment={payment}
                term={term}
                view={view}
                loan_fee={loan_fee}
                graceperiodchecked={graceperiodchecked}
                handleViewgraceperiod={handleViewgraceperiod}
                handleLoanfeeChange={handleLoanfeeChange}
                enableloanfee={enableloanfee}
                enableloanfeeChange={enableloanfeeChange}
                graceperiodChange={graceperiodChange}
                gracePeriod={gracePeriod}
                changeStep={changeStep}
                handleViewChange={handleViewChange}
                handleMoneyChange={handleMoneyChange}
                handlePercentageChange={handlePercentageChange}
                handleChange={handleChange}
              />
            }
            {step === 2 &&
              <Step2
                loan={newLoan}
                sender={sender}
                setSender={setSender}
                receiver={receiver}
                setReceiver={setReceiver}
                disabledSenderReceiver={transactionFriendId}
                friends={friends}
                transactionView={transactionView}
                paymentMethods={paymentMethods}
                changeStep={changeStep}
                handleChange={handleChange}
              /> 
            }
            {step === 3 &&
              <Step3
                loading={loading}
                loan={newLoan}
                payment={payment}
                term={term}
                view={view}
                transactionView={transactionView}
                paymentMethods={paymentMethods}
                SelectedFriendName={SelectedFriendName}
                changeStep={changeStep}
                sendTransfer={sendTransfer}
                loan_fee={loan_fee}
                enableloanfee={enableloanfee}
              /> 
            }
          </div>
        </div>
      </Col>
    </Container>
  )
}