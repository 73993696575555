import React, {createContext, useReducer} from 'react'

const initialState = {
  menu: false,
  lend: false,
  transactionFriendId: false,
  borrow: false,
	buySell: false
}
const store = createContext(initialState)
const { Provider } = store

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
		const newState = Object.assign({}, state)
		const type = action.type

		switch(type) {
      case 'transactionFriendId':
				newState[type] = action.value

				return newState
      default:
				newState[type] = !newState[type]

				return newState
    }
  }, initialState)

  return <Provider value={{ state, dispatch }}>{children}</Provider>
};

export { store, StateProvider }