import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { Alert, Button, Col, Container, Form, Image, Row, Spinner } from 'react-bootstrap'
import cx from 'classnames'
import TouchCarousel from 'react-touch-carousel'
import touchWithMouseHOC from 'react-touch-carousel/lib/touchWithMouseHOC'
import NonPassiveTouchTarget from '../../components/NonPassiveTouchTarget'
import PageTitle from '../../components/PageTitle'
import User from '../../helpers/User'
import bank from '../../assets/bank.png'
// import debitCard from '../../assets/debit-card.png'
// import creditCard from '../../assets/credit-card.png'
import scan from '../../assets/scan.png'
import './styles.scss'

const cardSize = 120
const cardPadCount = 3
// const carouselWidth = clamp(window.innerWidth, 0, 960)

export default function AddPayment() {
  const history = useHistory()
  const userData = new User().getData()
  // const [selectedCard, setSelectedCard] = useState(2);
  const selectedCard = 2
  const [newBankAccount, setNewBankAccount] = useState({
    id: userData.user.id
  })
  const [loading, setLoading] = useState(false)
  const [errorVisible, setErrorVisible] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const data = [
    // {
    //   background: selectedCard !== 0 ? '#FFF' : '#64B1FF',
    //   src: debitCard,
    //   class: selectedCard !== 0 ? 'wallet-tile ml-2' : 'wallet-tile-selected ml-2',
    //   text: 'Debit Card'
    // },
    // {
    //   background: selectedCard !== 1 ? '#FFF' : '#64B1FF',
    //   src: creditCard,
    //   class: selectedCard !== 1 ? 'wallet-tile ml-3' : 'wallet-tile-selected ml-3',
    //   text: 'Credit Card'
    // },
    {
      title: 'Card 3',
      background: selectedCard !== 2 ? '#FFF' : '#64B1FF',
      src: bank,
      class: selectedCard !== 2 ? 'wallet-tile ml-3' : 'wallet-tile-selected ml-3',
      text: 'Bank Account'
    }
  ]

  function errorScroll() {
    window.scrollTo(0, 0)
  }

  const handleChange = e => {
    const {name, value} = e.target
    setNewBankAccount({
      ...newBankAccount,
      [name]: value,
    })
  }

  const createBankAccount = async() => {
    setLoading(true)

    try{
      await axios.post('/api/funding-source', newBankAccount)

      setLoading(false)
      goHome()
    }catch(err) {
      setLoading(false)
      setErrorMessage(err.response.data.body._embedded.errors[0].message)
      errorScroll()
      setErrorVisible(true)
    }
  }

  const CarouselContainer = (props) => {
    const {cursor, carouselState: {active, dragging}, ...rest} = props
    let current = -Math.round(cursor) % data.length
    
    while (current < 0) {
      current += data.length
    }

    // Put current card at center
    // const translateX = (cursor - cardPadCount) * cardSize + (carouselWidth - cardSize) / 2 + 750
    return (
      <NonPassiveTouchTarget
        className={cx(
          'carousel-container',
          {
            'is-active': active,
            'is-dragging': dragging
          }
        )}
      >
        <NonPassiveTouchTarget
          className={'carousel-track mt-3 mb-3'}
          // style={{transform: `translate3d(${translateX}px, 0, 0)`}}
          {...rest}
        />
        {/* <div className='carousel-pagination-wrapper'>
          <ol className='carousel-pagination'>
            {data.map((_, index) => (
              <li
                key={index}
                className={current === index ? 'current' : ''}
              />
            ))}
          </ol>
        </div> */}
      </NonPassiveTouchTarget>
    )
  }
  
  const Carousel = touchWithMouseHOC(CarouselContainer)

  function goHome() {
    history.push('/wallet')
  }

  const renderCard = (index, modIndex) => {
    const item = data[modIndex]

    return (
      <div
        key={index}
        className={`carousel-card ${item.class}`}
      >
        <div
          className={'carousel-card-inner'}
          style={{backgroundColor: item.background}}
        >
          <Image className={'mt-4 carousel-image'} src={item.src} />
          {/* <div className='carousel-title'>{item.title}</div> */}
          <div className='carousel-text mt-3 text-center'>{item.text}</div>
        </div>
      </div>
    )
  }

  return (
    <div>
      <PageTitle
        title={'Settings / Wallet / New'}
        nav={'/wallet'}
      />
      <div className={'app-body app-body--page'}>
        <Container className={'text-center pt-3 pb-3'}>
          <Col
            lg={'6'}
            className={'mx-auto p-0'}
          >
            <div className={'pt-3 pb-3'}>
              <h2 className={'subtitle'}>Add Payment Method</h2>
              { errorVisible &&
                <Container className={'pt-3'}>
                  <Alert className={'mt-5 mb-0'} variant={'danger'} onClose={() => setErrorVisible(false)} dismissible>
                    <Alert.Heading>Payment Method Error!</Alert.Heading>
                    <p>
                      {errorMessage}
                    </p>
                  </Alert>
                </Container>
              }
              <div className={'wallet-carousel pt-3 mb-1'}>
                <TouchCarousel
                  component={Carousel}
                  cardCount={data.length}
                  cardSize={cardSize}
                  cardPadCount={cardPadCount}
                  renderCard={renderCard}
                  loop={false}
                />
              </div>
              { selectedCard === 0 &&
                <Form onSubmit={() => true} className={'text-left'}>
                  <Form.Group>
                    <Row className={'ml-0 mr-0'}>
                      <Col xs={'12'} className={'mt-4 pl-0 pr-0'}>
                        <Form.Control className={'pt-0'} type={'email'} placeholder={'Card Number'} onChange={() => true}></Form.Control>
                      </Col>
                      <Col xs={'12'} className={'mt-4 pl-0 pr-0'}>
                        <Form.Control className={'pt-0'} type={'text'} placeholder={'Cardholder Name'} onChange={() => true}></Form.Control>
                      </Col>
                      <Col xs={'3'} className={'mt-4 pl-0 pr-0'}>
                        <Form.Control className={'pt-0'} type={'text'} placeholder={'MM'} onChange={() => true}></Form.Control>
                      </Col>
                      <Col xs={'3'} className={'mt-4 pl-0 pr-0'}>
                        <Form.Control className={'pt-0'} type={'text'} placeholder={'YYYY'} onChange={() => true}></Form.Control>
                      </Col>
                      <Col xs={'6'} className={'mt-4 pl-0 pr-0'}>
                        <Form.Control className={'pt-0'} type={'text'} placeholder={'CVV'} onChange={() => true}></Form.Control>
                      </Col>
                      <Col xs={'12'} className={'mt-4'}>
                        <Button variant={'link'} className={'w-100 text-center mt-4'} disabled>
                            <Image src={scan} />
                            <div>Scan Card<br/><em>(coming soon)</em></div>
                        </Button>
                      </Col>
                      <Col xs={'12'} className={'mt-4'}>
                        <Button size={'lg'} variant={'primary'} className={'w-100 text-center mt-4'}>
                            Add Debit Card
                        </Button>
                      </Col>
                    </Row>
                  </Form.Group>
                </Form>
              }
              { selectedCard === 1 &&
                <Form onSubmit={() => true} className={'text-left'}>
                  <Form.Group>
                    <Row className={'ml-0 mr-0'}>
                      <Col xs={'12'} className={'mt-4 pl-0 pr-0'}>
                        <Form.Control className={'pt-0'} type={'email'} placeholder={'Card Number'} onChange={() => true}></Form.Control>
                      </Col>
                      <Col xs={'12'} className={'mt-4 pl-0 pr-0'}>
                        <Form.Control className={'pt-0'} type={'text'} placeholder={'Cardholder Name'} onChange={() => true}></Form.Control>
                      </Col>
                      <Col xs={'3'} className={'mt-4 pl-0 pr-0'}>
                        <Form.Control className={'pt-0'} type={'text'} placeholder={'MM'} onChange={() => true}></Form.Control>
                      </Col>
                      <Col xs={'3'} className={'mt-4 pl-0 pr-0'}>
                        <Form.Control className={'pt-0'} type={'text'} placeholder={'YYYY'} onChange={() => true}></Form.Control>
                      </Col>
                      <Col xs={'6'} className={'mt-4 pl-0 pr-0'}>
                        <Form.Control className={'pt-0'} type={'text'} placeholder={'CVV'} onChange={() => true}></Form.Control>
                      </Col>
                      <Col xs={'12'} className={'mt-4'}>
                        <Button variant={'link'} className={'w-100 text-center mt-4'} disabled>
                            <Image src={scan} />
                            <div>Scan Card<br/><em>(coming soon)</em></div>
                        </Button>
                      </Col>
                      <Col xs={'12'} className={'mt-4'}>
                        <Button size={'lg'} variant={'primary'} className={'w-100 text-center mt-4'}>
                            Add Credit Card
                        </Button>
                      </Col>
                    </Row>
                  </Form.Group>
                </Form>
              }
              { selectedCard === 2 &&
                <Form onSubmit={() => true} className={'text-left'}>
                  <Form.Group>
                    <Row className={'ml-0 mr-0'}>
                      <Col xs={'12'} className={'mt-4 pl-0 pr-0'}>
                        <Form.Control className={'pt-0'} type={'text'} name={'routing'} placeholder={'Routing Number'} onChange={e => handleChange(e)}></Form.Control>
                      </Col>
                      <Col xs={'12'} className={'mt-4 pl-0 pr-0'}>
                        <Form.Control className={'pt-0'} type={'text'} name={'account'} placeholder={'Account Number'} onChange={e => handleChange(e)}></Form.Control>
                      </Col>
                      <Col xs={'12'} className={'mt-4 pl-0 pr-0'}>
                        <Form.Control className={'pt-0'} type={'text'} name={'nickname'} placeholder={'Name for Account'} onChange={e => handleChange(e)}></Form.Control>
                      </Col>
                      {/* <Col xs={'12'} className={'mt-4 pl-0 pr-0'}>
                        <small>ACCOUNT TYPE</small>
                        <div>
                          <Form.Check type={'radio'} label={'Checking'} inline />
                          <Form.Check type={'radio'} label={'Savings'} inline />
                        </div>
                      </Col> */}
                      <Col xs={'12'} className={'pl-0 pr-0 mt-4 text-center'}>
                        <Button size={'lg'} variant={'primary'} className={'w-50 text-center mt-4'} disabled={!newBankAccount.routing || !newBankAccount.account || !newBankAccount.nickname || loading} onClick={createBankAccount}>
                            {loading ? <Spinner animation={'border'} role={'status'} variant={'light'} /> : 'Add Account' }
                        </Button>
                      </Col>
                    </Row>
                  </Form.Group>
                </Form>
              }
            </div>
          </Col>
        </Container>
      </div>
    </div>
  )
}