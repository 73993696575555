import React, { useContext } from 'react'
import { Drawer } from 'baseui/drawer'
import BuySell from '../BuySell'
import LendBorrow from '../LendBorrow'
import { store } from '../../helpers/Store'

export default function Transactions({ type }) {
  const globalState = useContext(store)
  const { state, dispatch } = globalState

  const handleClose = () => {
    dispatch({type: type})
    dispatch({type: 'transactionFriendId', value: false})
  }

  return (
    <Drawer
      anchor={'bottom'}
      isOpen={state[type]}
      onClose={() => handleClose()}
      showBackdrop
      overrides={{
        Root: {
          style: {
            zIndex: 2
          }
        },
        DrawerContainer: {
          style: {
            backgroundColor: '#64b1ff !important',
            height: '100vh'
          }
        },
        DrawerBody: {
          style: {
            margin: 0
          }
        },
        Close: {
          style: {
            fill: 'white',
            top: '0',
            left: '0',
            height: '50px',
            width: '50px',
            position: 'fixed'
          }
        }
      }}
    >
      {(type === 'buySell') ?
        <BuySell />
      :
        <LendBorrow transactionView={type} />
      }
    </Drawer>
  )
}