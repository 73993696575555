import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Col, Container, Image, Form, NavLink, Row } from 'react-bootstrap'
import { Image as Cloudinary } from 'cloudinary-react'
import axios from 'axios'
import QRCode from 'qrcode.react'
import User from '../../helpers/User'
import editAvatar from '../../assets/edit-avatar.png'
import blankAvatar from '../../assets/blank-avatar.png'
import done from '../../assets/done.png'
import './styles.scss'

export default function UserPanel({ setSettingsView, settingsView }) {
  const userData = new User().getData()
  const user = userData.user
  const history = useHistory()
  const inputFile = useRef(null)
  const [ avatar, setAvatar ] = useState(null)
  
  const triggerFileInput = () => {
    inputFile.current.click()
  }

  const getAvatar = async() => {
    const results = await axios.get(`/api/avatar/${user.id}`)

    setAvatar(results.data[0] ? results.data[0] : null)
  }

  const uploadAvatar = async() => {
    const file = inputFile.current.files[0]
    
    if (file) {
      const reader = new FileReader()

      reader.readAsDataURL(file)
      reader.onloadend = async() => {
        await axios.post('/api/avatar', { user: user.id, file: reader.result })

        getAvatar()
      }
    }
  }

  const navigateToWallet = () => {
    history.push('/wallet')
  }

  useEffect(() => {
    getAvatar()
  }, [])

  return (
    <Container className={'text-center pt-3 pb-3 pl-0 pr-0'}>
      {
        settingsView ?
        <div className={'settings-panel'}>
          <header className={'pt-2 text-center filled-white'}>
            <strong className={'d-block mt-1'}>Information</strong>
            <Button
              className={'settings-btn'}
              variant={'link'}
              onClick={() => setSettingsView(false)}
            >
              <Image
                roundedCircle
                src={done}
              />
            </Button>
          </header>
          <div className={'user-avatar mt-5'}>
            {avatar ?
                <Cloudinary
                  cloudName={'dvfifodns'}
                  publicId={avatar.file_name}
                  width={200}
                  height={200}
                  crop={'thumb'}
                  gravity={'face'}
                />           
              :
                <Image roundedCircle src={blankAvatar} />
            }
            <Button
              className={'edit-avatar mt-4'}
              variant={'link'}
              onClick={() => triggerFileInput()}
            >
              <Image src={editAvatar} />
            </Button>
            <Form>
              <Form.File
                ref={inputFile}
                style={{display: 'none'}}
                onChange={() => uploadAvatar()}
              />
            </Form>
          </div>
          <div className={'text-danger mt-3'}>
            <strong><em>All fields are required to <NavLink className={'p-0 d-inline'} onClick={navigateToWallet}>add bank accounts</NavLink>.</em></strong>
          </div>
          <Form
            onSubmit={() => true}
            className={'mt-3 text-left'}
          >
            <Form.Group>
              <Row className={'ml-0 mr-0'}>
                <Col
                  xs={'6'}
                  className={'pl-0 pr-2'}
                >
                  <small>FIRST NAME</small>
                  <Form.Control
                    className={'pt-0'}
                    type={'text'}
                    placeholder={'Enter First Name'}
                    onChange={() => true}
                  />
                </Col>
                <Col
                  xs={'6'}
                  className={'pl-2 pr-0'}
                >
                  <small>LAST NAME</small>
                  <Form.Control
                    className={'pt-0'}
                    type={'text'}
                    placeholder={'Enter Last Name'}
                    onChange={() => true}
                  />
                </Col>
                <Col
                  xs={'12'}
                  className={'mt-2 pl-0 pr-0'}
                >
                  <small>EMAIL</small>
                  <Form.Control
                    className={'pt-0'}
                    type={'email'}
                    placeholder={'Enter Email'}
                    onChange={() => true}
                  />
                </Col>
                <Col
                  xs={'12'}
                  className={'mt-2 pl-0 pr-0'}
                >
                  <small>PHONE NUMBER</small>
                    <Form.Control
                      className={'pt-0'}
                      type={'text'}
                      placeholder={'Enter Phone Number'}
                      onChange={() => true}
                    />
                </Col>
                <Col
                  xs={'12'}
                  className={'mt-2 pl-0 pr-0'}
                >
                  <small>USERNAME</small>
                    <Form.Control
                      className={'pt-0'}
                      type={'text'}
                      placeholder={'Enter Username'}
                      onChange={() => true}
                    />
                </Col>
              </Row>
            </Form.Group>
            <h6 className={'mt-3 text-center'}>Click the checkmark to save changes.</h6>
          </Form>
          {/* <div className={'premium mt-4'}>
            <Button
              variant={'primary'}
              className={'w-100'}
              disabled
            >
              <Image
                className={'ml-1'}
                src={premium}
              />
              <span>Go Premium</span>
              <Image
                className={'mr-1'}
                src={caretRight}
              />
            </Button>
            <small><em>(coming soon)</em></small>
          </div> */}
        </div> :
        <div>
          {/* <Button
            className={'settings-btn'}
            variant={'link'}
            onClick={() => setSettingsView(true)}
          >
            <Image
              roundedCircle
              src={settings}
            />
          </Button> */}
          <div className={'user-avatar mt-4'}>
            {avatar ?
                <Cloudinary
                  cloudName={'dvfifodns'}
                  publicId={avatar.file_name}
                  width={200}
                  height={200}
                  crop={'thumb'}
                  gravity={'face'}
                />           
              :
              <Image roundedCircle src={blankAvatar} />
            }
            <Button
              className={'edit-avatar mt-4'}
              variant={'link'}
              onClick={() => triggerFileInput()}
            >
              <Image src={editAvatar} />
            </Button>
            <Form>
              <Form.File
                ref={inputFile}
                style={{display: 'none'}}
                accept={'image/*'}
                onChange={() => uploadAvatar()}
              />
            </Form>
            <h4 className={'mt-4 text-light'}>{user.first_name + ' ' + user.last_name}</h4>
            <h6 className={'mt-2 text-primary'}><em>Standard User</em></h6>
          </div>
          <div className={'user-tag filled text-light h-100 pt-2'}>
            <h6 className={'mt-0'}>{user.email}</h6>
            <div className={'mt-0'}><em>(Premium features coming soon!)</em></div>
            <QRCode
              className={'mt-4'}
              value={`${process.env.REACT_APP_BASE_URL}/friends?user_id=${user.id}`}
            />
            {/* <div className={'mt-4'}>Click on the gear above to edit your profile.</div> */}
          </div>
          {/* <div className={'premium'}>
            <Button
              variant={'primary'}
              className={'w-100'}
              disabled
            >
              <Image
                className={'ml-1'}
                src={premium}
              />
              <span>Go Premium</span>
              <Image
                className={'mr-1'}
                src={caretRight}
              />
            </Button>
            <small><em>(coming soon)</em></small>
          </div> */}
          {/* <div className={'user-qr-code mt-4'}>
            <Image src={qrCode} />
          </div>
          <div className={'user-options mt-3'}>
            <Image src={envelope} />
            <Image
              className={'pl-3 pr-3'}
              src={editAvatar}
            />
            <Image src={share} />
          </div> */}
        </div>
      }     
    </Container>
  )
}