import React from 'react'
import Content from '../../components/Content'
import Dashboard from '../../components/Dashboard'

export default function Landing() {
  return (
    <Content page={'landing'}>
      <Dashboard />
    </Content>
  )
}