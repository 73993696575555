import React, { useContext } from 'react'
import { Drawer } from 'baseui/drawer'
import OptionsMenu from '../OptionsMenu'
import { store } from '../../helpers/Store'

export default function Menu() {
  const globalState = useContext(store)
  const { state, dispatch } = globalState

  return (
    <Drawer
      anchor={'bottom'}
      isOpen={state['menu']}
      onClose={() => dispatch({type: 'menu'})}
      autoFocus={false}
      showBackdrop
      overrides={{
        Root: {
          style: {
            zIndex: 2
          }
        },
        DrawerContainer: {
          style: {
            backgroundColor: 'transparent !important',
            height: '200px'
          }
        },
        DrawerBody: {
          style: {
            margin: 0
          }
        },
        Close: {
          style: {
            top: 'calc(100% - 77px)',
            right: 'calc(50% - 28px)',
            position: 'fixed',
            fill: 'white',
            height: '56px',
            width: '56px',
            background: '#64b1ff',
            borderRadius: '50%',
            boxShadow: 'rgba(0, 0, 0, 0.5) 0 2px 4px 0',
          }
        }
      }}
    >
      <OptionsMenu />
    </Drawer>
  )
}