import React from 'react'
import Content from '../../components/Content'
import PageTitle from '../../components/PageTitle'
import SettingsMenu from '../../components/SettingsMenu'

export default function Settings() {
  return (
    <Content page={'settings'}>
      <PageTitle title={'Settings'} />
      <SettingsMenu />
    </Content>
  )
}