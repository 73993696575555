import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios'
import { Button, Col, Container, Form, Image, Nav, Spinner } from 'react-bootstrap'
import Swal from 'sweetalert2'
import User from '../../helpers/User'
import logo from '../../assets/logo.png'
import './styles.scss'

const icons = {
  'user': require('../../assets/user.png'),
  'padlock': require('../../assets/padlock.png')
}

export default function Login() {
  const [user, setUser] = useState('')
  const [pass, setPass] = useState('')
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  const handleSubmit = async(e) => {
    e.preventDefault()
    
    setLoading(true)
    
    new User().removeData()
    
    try {
      const resp = await axios.post('/api/login', { username: user, password: pass })
      const state = {
        user: resp.data,
        loggedIn: true,
      }
      
      new User().setData(state)

      postLoginNavigate()
    } catch(err) {
      await Swal.fire({
        icon: 'error',
        title: 'Error!',
        html: err.response.data
      })
      
      new User().removeData()
      setLoading(false)
    }
  }

  const postLoginNavigate = async() => {
    const data = new User().getData()
    const id = data && 'user' in data && data.user.id
    let redirectUrl = '/walkthrough'
    
    if(id){
      const resp = await axios.get(`/api/walkthrough/${id}`)

      if(resp.data && resp.data.length > 0)
        redirectUrl = window.privateRedirect || '/pari'
    }
        
    history.push(redirectUrl)
  }

  return (
    <div className={'login'}>
      <Container>
        <Col sm={'6'} className={'mx-auto'}>
          <div className={'pt-5 pb-5'}>
            <Image
              src={logo}
              className={'main-logo'}
            />
          </div>
          <Form onSubmit={e => handleSubmit(e)}>
            <h2 className={'subtitle'}>Login</h2>
            <div className={'input-icon mt-4'}>
              <label htmlFor={'email'}>
                <Image src={icons['user']} />
              </label>
              <Form.Control
                id={'email'}
                autoComplete={'email'}
                type={'email'}
                placeholder={'Email address'}
                required={true}
                onChange={(e) => setUser(e.target.value)}
              />
            </div>
            <div className={'input-icon mt-4'}>
              <label htmlFor={'password'}>
                <Image src={icons['padlock']} />
              </label>
              <Form.Control
                id={'password'}
                autoComplete={'current-password'}
                type={'password'}
                placeholder={'Password'}
                required={true}
                onChange={(e) => setPass(e.target.value)}
              />
            </div>
            <div className={'mt-4'}>
              <span>Forgot your password? </span>
              <Nav.Link
                as={Link}
                to={'/forgot-password'}
              >
                <b>Reset Here</b>
              </Nav.Link>
            </div>
            <div className={'mt-4'}>
              <Button
                className={'w-100'}
                size={'lg'}
                type={'submit'}
                disabled={loading}
              >
                {loading ?
                    <Spinner
                      as={'span'}
                      animation={'border'}
                    />
                  :
                    <span>SIGN IN</span>
                }
              </Button>
            </div>
          </Form>
          <div className={'pt-5 pb-5'}>
            Don't have an account?
            <Button
              className={'w-100 mt-4'}
              variant={'outline-primary'}
              size={'lg'}
              as={Link}
              to={'/sign-up'}
              disabled={loading}
            >
              SIGN UP
            </Button>
          </div>
        </Col>
      </Container>
    </div>
  )
}