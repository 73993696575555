import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { Alert, Button, Card, Col, Container, Form, Modal, Row, Spinner, Badge  } from 'react-bootstrap'
import PageTitle from '../../components/PageTitle'
import User from '../../helpers/User'
import './styles.scss'
import Swal from 'sweetalert2'

export default function Wallet() {
  const userData = new User().getData()
  const userId = userData.user.id
  const history = useHistory()

  const [bankAccounts, setBankAccounts] = useState([])
  const [debitCards, setDebitCards] = useState([])
  // const [showModal, setShowModal] = useState(false)
  // const [visibleModal, setVisibleModal] = useState(null)
  // const [valOne, setValOne] = useState(null)
  // const [valTwo, setValTwo] = useState(null)
  const [loading, setLoading] = useState(false)
  // const [errorVisible, setErrorVisible] = useState(false)
  // const [errorMessage, setErrorMessage] = useState('')
  // const handleShow = () => setShowModal(true)
  // const handleHide = () => setShowModal(false)

  // function handleModal(idx) {
  //   setVisibleModal(idx)
  //   handleShow()
  // }

  // const verifyBankAccount = async(id) => {
  //   setLoading(true)

  //   try{
  //     await axios.post('/api/funding-source/verify', { id, valOne, valTwo })

  //     setLoading(false)
  //     history.go(0)
  //   }catch(err) {
  //     setLoading(false)
  //     setErrorMessage(err.response.data.body._embedded.errors[0].message)
  //     setErrorVisible(true)
  //   }
  // }
	
  useEffect(() => {
		const controller = new AbortController()
		setLoading(true)
		const fetchData = async() => {
			setLoading(true)

		  const bankAccountsPromise = await axios.get(`/api/funding-source/${userId}`)
		  const debitCardsPromise = await axios.get(`/api/debit-cards/${userId}`)
		  setBankAccounts(bankAccountsPromise.data)
		  setDebitCards(debitCardsPromise.data)
		  setLoading(false)
		}
    
		if(userId)
		  fetchData()

			return () => {
				controller.abort()
		}
	  // eslint-disable-next-line react-hooks/exhaustive-deps
	  }, [ userId ])
	  
		const deleteAccount = async(account) => {
			var result = window.confirm("Are you sure you want to delete?");
			if (result) {
				
				  
				let dwollaPath = '';
				if (account.dwollaPath) {
					dwollaPath = account.dwollaPath;
				}
				const body = {
				   userId: userId,
				  _id: account._id,
				  dwollaPath: dwollaPath,
				 
				};
				setLoading(true)
				setBankAccounts([])
				setDebitCards([])
				await axios.post(`/api/funding-source/delete`, body)
				  .then(res => {
					
					if (res.data.status == false) {
						Swal.fire({
							icon: 'error',
							title: 'Oops..',
							html: res.data.message
						  });
					} else {
						Swal.fire({
							icon: 'success',
							title: 'Success!',
							html: 'Bank account has been deleted'
						  });
						
				
					}
				  })
				  .catch(error => {
				  });
				
			
				
				const bankAccountsPromise = await axios.get(`/api/funding-source/${userId}`)
				const debitCardsPromise = await axios.get(`/api/debit-cards/${userId}`)
				setBankAccounts(bankAccountsPromise.data)
				setDebitCards(debitCardsPromise.data)
				setLoading(false)
			}
		}
  return (
    <div>
      <PageTitle
        title={'Settings / Wallet'}
        nav={'/settings'}
      />
      <div className={'app-body app-body--page'}>
        <Container className={'text-center pt-3 pb-3'}>
          <Col
            lg={'6'}
            className={'mx-auto p-0'}
          >
            <div className={'pt-3 pb-3'}>
              <h2 className={'subtitle'}>Existing Bank Accounts</h2>
              {bankAccounts && bankAccounts.length > 0 ? bankAccounts.map((pm, idx) => (
                <Card
                  className={'mt-3 mx-2 d-inline-block wallet-card'}
                  bg={'light'}
                  text={'dark'}
                  key={idx}
                >
                  <Card.Header>
				    <Badge  className={'delete-icon'} onClick={e => deleteAccount(pm)}>X</Badge>
                    <strong>{pm.bankName}<br/>...{pm.identifier}</strong>
                    <div>
                      <small className={'text-light'}>Verified through Plaid</small>
                      {/* {
                        pm.verified ? <small className={'text-success'}>Verified</small> : <Button variant={'link'} onClick={() => handleModal(idx)}><span className={'text-danger'}>Click to Verify</span></Button>
                      } */}
                    </div>
                  </Card.Header>
                  <Card.Body className={'p-0'}>
                    <small>{pm.nickname}</small>
                    {/* <Modal show={showModal && visibleModal === idx} onHide={handleHide}>
                      <Modal.Header closeButton>
                        <Modal.Title>Verify Account X-{pm.identifier}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        { errorVisible &&
                          <Container className={'pt-1'}>
                            <Alert
                              className={'mb-0'}
                              variant={'danger'}
                              onClose={() => setErrorVisible(false)}
                              dismissible
                            >
                              <Alert.Heading>Verification Error!</Alert.Heading>
                              <p>
                                {errorMessage}
                              </p>
                            </Alert>
                          </Container>
                        }
                        <Form className={'text-center'}>
                          <small><em>Please enter the amount of the two micro-deposits (less than $1.00) made to your bank:</em></small>
                          <Row className={'mt-3'}>
                            <Col xs={'6'}>
                              <Form.Label className={'pb-0'}>Deposit 1:</Form.Label>
                              <Form.Control
                                onChange={e => setValOne(e.target.value)}
                                type={'text'}
                                placeholder={'Amount'}
                              />
                            </Col>
                            <Col xs={'6'}>
                              <Form.Label className={'pb-0'}>Deposit 2:</Form.Label>
                              <Form.Control
                                onChange={e => setValTwo(e.target.value)}
                                type={'text'}
                                placeholder={'Amount'}
                              />
                            </Col>
                          </Row>
                        </Form>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          variant={'success'}
                          className={'ml-2'}
                          disabled={!valOne || !valTwo || loading}
                          onClick={() => verifyBankAccount(pm._id)}
                        >
                          {loading ? <Spinner animation={'border'} role={'status'} variant={'light'} /> : 'Confirm Bank Account' }
                        </Button>
                      </Modal.Footer>
                    </Modal> */}
                  </Card.Body>
                </Card>
              )) : (
                <div className="mt-3">No bank accounts to display. Please add one below.</div>
              )}
              {/* <h2 className={'mt-4 subtitle'}>Existing Debit Cards</h2>
              {debitCards && debitCards.length > 0 ? debitCards.map((pm, idx) => (
                <Card
                  className={'mt-3 mx-2 d-inline-block wallet-card'}
                  bg={'light'}
                  text={'dark'}
                  key={idx}
                >
                  <Card.Header>
                    <strong>{pm.nickname}</strong>
                    <div>
                      <small className={'text-light'}>Verified</small>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <small>Debit Card</small>
                  </Card.Body>
                </Card>
              )) : (
                <div className="mt-3">No debit cards to display. Please add one below.</div>
              )} */}
              {loading ? <Spinner animation={'border'} role={'status'} variant={'primary'} /> : null}
            </div>
            <Button
              className={'d-block mx-auto mt-3'}
              onClick={() => history.push(`/payment-method-plaid/${userId}`)}
            >
              Add New Bank Account using Plaid
            </Button>
            {/* <Button
              className={'d-block mx-auto mt-3'}
              onClick={() => history.push(`/payment-method-debit/${userId}`)}
            >
              Add New Debit Card
            </Button> */}
          </Col>
        </Container>
      </div>
    </div>
  )
}