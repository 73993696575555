import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Col, Container } from 'react-bootstrap'
import axios from 'axios'
import Swal from 'sweetalert2'
import User from '../../helpers/User'
import { store } from '../../helpers/Store'
import { Step1, Step2, Step3 } from './Steps'
import './styles.scss'

export default function BuySell() {
  const history = useHistory()
  const globalState = useContext(store)
  const { state, dispatch } = globalState
  const transactionFriendId = state['transactionFriendId']
  
  const [view, setView] = useState(0)
  const [step, setStep] = useState(1)
  const [loading, setLoading] = useState(false)
  const [fees, setFees] = useState(0)
  const [total, setTotal] = useState(0)
  const [sender, setSender] = useState(transactionFriendId)
  const [receiver, setReceiver] = useState(transactionFriendId)
  const [newPurchase, setNewPurchase] = useState({})
  const [payment, setPayment] = useState(Number((0).toFixed(2)))
  const [friends, setFriends] = useState([])
  const [paymentMethods, setPaymentMethods] = useState([])
  const userData = new User().getData()
  const userId = userData.user.id

  useEffect(() => {
    const controller = new AbortController()

    const fetchData = async() => {
      const fundingRes = await axios.get(`/api/funding-source/${userId}`)
      const friendsRes = await axios.get(`/api/friends/${userId}`)

      setPaymentMethods(fundingRes.data)
      setFriends(friendsRes.data)
    }

    if(userId)
      fetchData()

    return () => {
			controller.abort()
		}
  }, [userId])

  useEffect(() => {
    setNewPurchase({
      ...newPurchase,
      ['total-mask']: `$ ${total.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
      ['fees-mask']:  `$ ${fees.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
    })
  }, [total, fees])

  useEffect(() => {
    const amount = Number(newPurchase.amount) - Number(newPurchase.down)
    const rate = newPurchase.rate / 100 / 12
    const newTotal = Number((amount * 0.0125).toFixed(2) > 12.5 ? (amount + 12.5).toFixed(2) : (amount * 1.0125).toFixed(2))
  
    if (Number(newTotal) > 0 && newPurchase.rate >= 0 && Number(newPurchase.down) >= 0 && newPurchase.term > 0) {
      if (rate > 0) {
        setPayment(Number((newTotal * rate * (Math.pow(1 + rate, newPurchase.term)) / (Math.pow(1 + rate, newPurchase.term) - 1)).toFixed(2)));
      } else {
        setPayment(Number((newTotal / newPurchase.term).toFixed(2)));
      }
    }
    setTotal(newTotal)
    setFees(newTotal - Number(amount))
  }, [newPurchase, view])

  const changeStep = (e, number) => {
    e.preventDefault()

    setStep(number)
  }

  const handleChange = e => {
    const {name, value} = e.target
    
    setNewPurchase({
      ...newPurchase,
      [name]: name !== 'notes' ? Number(value) : value
    })
  }

  const handleMoneyChange = (e, value, maskedValue) => {
    const { name } = e.target
    
    setNewPurchase({
      ...newPurchase,
      [name]: value,
      [`${name}-mask`]: maskedValue
    })
  }

  const handlePercentageChange = (name, value) => {
    setNewPurchase({
      ...newPurchase,
      [name]: Number(value)
    })
  }

  const handleViewChange = e => {
    e.persist()

    setView(Number(e.target.value))
  }

  const sendTransfer = async() => {
    setLoading(true)

    const transfer = {
      amount: total,
      payment: payment,
      rate: newPurchase.rate,
      term: newPurchase.term,
      notes: newPurchase.notes,
      method: paymentMethods[newPurchase.method]._id,
      sender: view === 0 ? sender : userId,
      receiver: view === 0 ? userId : receiver,
      down: newPurchase.down,
      initiatedBy: userId,
      initiated: Date.now()
    }

    try{
      await axios.post('/api/transfers', transfer)

      dispatch({type: 'buySell'})
      dispatch({type: 'transactionFriendId', value: false})
      
      await Swal.fire({
        icon: 'success',
        title: 'Success!'
      })

      if(history.location.pathname === '/pari' || history.location.pathname === '/history')
        history.go(0)

      setLoading(false)
    }catch(err){
      console.log(err)
      await Swal.fire({
        icon: 'error',
        title: 'Error!',
        html: err.response.data
      })

      setLoading(false)
    }
  }

  const SelectedFriendName = () => {
    const friendId = view === 0 ? sender : receiver
    const friend = friends.find(x => x.user._id === friendId)
    
    return (
      <span>
        {friend.user.first_name} {friend.user.last_name}
      </span>
    )
  }

  return (
    <Container className={'buysell'}>
      <Col sm={'6'} className={'mx-auto pt-3 pb-4'}>
        <div className={'signup__header'}>
          <h1 className={'title'}>{ view === 0 ? "New Purchase" : "New Sale" }</h1>
        </div>
        <div className={'signup__form'}>
          <div className={'signup__stepper'}>
            {step === 1 &&
              <Step1
                purchase={newPurchase}
                payment={payment}
                changeStep={changeStep}
                handleViewChange={handleViewChange}
                handleMoneyChange={handleMoneyChange}
                handlePercentageChange={handlePercentageChange}
                handleChange={handleChange}
              />
            }
            {step === 2 &&
              <Step2
                purchase={newPurchase}
                sender={sender}
                setSender={setSender}
                receiver={receiver}
                setReceiver={setReceiver}
                disabledSenderReceiver={transactionFriendId}
                friends={friends}
                view={view}
                paymentMethods={paymentMethods}
                changeStep={changeStep}
                handleChange={handleChange}
              /> 
            }
            {step === 3 &&
              <Step3
                loading={loading}
                purchase={newPurchase}
                view={view}
                paymentMethods={paymentMethods}
                SelectedFriendName={SelectedFriendName}
                changeStep={changeStep}
                sendTransfer={sendTransfer}
              /> 
            }
          </div>
        </div>
      </Col>
    </Container>
  )
}