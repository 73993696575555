import React, { useState } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { Container, Col, Image, Nav, Row, Spinner } from 'react-bootstrap'
import { Image as Cloudinary } from 'cloudinary-react'
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom'
import { Drawer } from 'baseui/drawer'
import QRCode from 'qrcode.react'
import { ChevronRight } from 'react-bootstrap-icons'
import Card from '../Card'
import UserPanel from '../UserPanel'
import User from '../../helpers/User'
import blankAvatar from '../../assets/blank-avatar.png'
import './styles.scss'
import Button from 'react-bootstrap/Button';

export default function Dashboard() {
  const { user } = new User().getData()
  const history = useHistory()
  const id = user.id
  const [ notifications, setNotifications ] = useState([])
  const [ upcomingPayments, setUpcomingPayments ] = useState([])
  const [ showModal, setShowModal ] = useState(false)
  const [ visibleModal, setVisibleModal ] = useState(null)
  const [ showFriendModal, setShowFriendModal ] = useState(false)
  const [ visibleFriendModal, setVisibleFriendModal ] = useState(null)
  const [ userPanelModal, setUserPanelModal ] = useState(false)
  const [ accounts, setAccounts ] = useState([])
  const [ avatar, setAvatar ] = useState(null)
  const [ loading, setLoading ] = useState(false)
  let index = 0

  const handleShow = () => setShowModal(true)
  const handleHide = () => setShowModal(false)
  const handleFriendShow = () => setShowFriendModal(true)
  const handleFriendHide = () => setShowFriendModal(false)
  const monthlyPayment = (amount, rate, term) => rate > 0 ? Number((amount * rate * (Math.pow(1 + rate, term)) / (Math.pow(1 + rate, term) - 1)).toFixed(2)) : parseFloat(Number(amount / term)).toFixed(2)
  
  function handleModal(idx) {
    setVisibleModal(idx)
    handleShow()
  }

  function handleFriendModal(idx) {
    setVisibleFriendModal(idx)
    handleFriendShow()
  }
	function viewallnotifications() {
		
	}

  const notificationsSort = (notifications) => {
    return notifications.sort((n1, n2) => {
      const a = n1.initiated
      const b = n2.initiated

      return new Date(b).getTime() - new Date(a).getTime()
    })
  }
  
  const dismissNotification = async(id) => {
    await axios.put(`/api/notifications/${id}/dismiss`)
  }

  const acceptFriendRequest = async(friendId, userId) => {
    await axios.put(`/api/relationships/${friendId}/${userId}/accept`)
  }

  const showNotifications = () => {
    notifications['order'].map((date) => {
      notifications[date].forEach(n => {
        if(!n.dismissed) {
          if (n.type === 'relationshipAccepted' && n.relationship && id === n.relationship.userOne) {
            Swal.fire({
              title: 'Relationship Accepted',
              text: `You and ${n.receiver.first_name} ${n.receiver.last_name} are now friends!`,
              icon: 'success',
              confirmButtonText: 'Dismiss',
              showCancelButton: true,
              cancelButtonText: 'Ignore'
            })
              .then((result) => {
                if (result.isConfirmed) {
                  dismissNotification(n._id)
                  history.go(0)
                } else {
                  return
                }
              })
          }
          if (n.type === 'relationship' && id === n.relationship.userTwo) {
            Swal.fire({
              title: 'Relationship Request',
              text: `${n.sender.first_name} ${n.sender.last_name} has invited you to be friends on Pari!`,
              icon: 'info',
              confirmButtonText: 'Accept',
              showCancelButton: true,
              cancelButtonText: 'Decline'
            })
              .then((result) => {
                if (result.isConfirmed) {
                  acceptFriendRequest(n.relationship.userOne, n.relationship.userTwo)
                  dismissNotification(n._id)
                  history.go(0)
                } else {
                  return
                }
              })
          }
          // if (n.type === 'transfer' && n.transfer.initiatedBy !== id) {
          //   if (n.transfer.initiatedBy === n.sender._id) {
          //     Swal.fire({
          //       title: 'Transfer Request',
          //       text: `${n.sender.first_name} ${n.sender.last_name} has requested a transaction with you. Please check the dashboard to accept/decline!`,
          //       icon: 'info',
          //       confirmButtonText: 'OK',
          //     })
          //     .then((result) => {
          //       if (result.isConfirmed) {
          //         dismissNotification(n._id)
          //         history.go(0)
          //       }
          //     })
          //   } else {
          //     Swal.fire({
          //       title: 'Transfer Request',
          //       text: `${n.receiver.first_name} ${n.receiver.last_name} has requested a transaction with you. Please check the dashboard to accept/decline!`,
          //       icon: 'info',
          //       confirmButtonText: 'OK',
          //     })
          //     .then((result) => {
          //       if (result.isConfirmed) {
          //         dismissNotification(n._id)
          //         history.go(0)
          //       }
          //     })
          //   }
          // }
          if (n.type === 'transferAccepted') {
            if (n.transfer.initiatedBy !== n.sender._id && id === n.transfer.initiatedBy) {
              Swal.fire({
                title: 'Transfer Accepted',
                text: `${n.sender.first_name} ${n.sender.last_name} has accepted your transfer! Please see the dashboard for more information.`,
                icon: 'success',
                confirmButtonText: 'OK',
              })
                .then((result) => {
                  if (result.isConfirmed) {
                    dismissNotification(n._id)
                    history.go(0)
                  } else {
                    return
                  }
                })
            } else if (id === n.transfer.initiatedBy) {
              Swal.fire({
                title: 'Transfer Accepted',
                text: `${n.receiver.first_name} ${n.receiver.last_name} has accepted your transfer! Please see the dashboard for more information.`,
                icon: 'success',
                confirmButtonText: 'OK',
              })
                .then((result) => {
                  if (result.isConfirmed) {
                    dismissNotification(n._id)
                    history.go(0)
                  } else {
                    return
                  }
                })
            }
          }
          if (n.type === 'transferDeclined') {
            if (n.transfer.initiatedBy !== n.sender._id && id === n.transfer.initiatedBy) {
              Swal.fire({
                title: 'Transfer Declined',
                text: `${n.sender.first_name} ${n.sender.last_name} has declined your transfer! Please see the dashboard for more information.`,
                icon: 'error',
                confirmButtonText: 'OK',
              })
                .then((result) => {
                  if (result.isConfirmed) {
                    dismissNotification(n._id)
                    history.go(0)
                  } else {
                    return
                  }
                })
            } else if (id === n.transfer.initiatedBy) {
              Swal.fire({
                title: 'Transfer Declined',
                text: `${n.receiver.first_name} ${n.receiver.last_name} has declined your transfer! Please see the dashboard for more information.`,
                icon: 'error',
                confirmButtonText: 'OK',
              })
                .then((result) => {
                  if (result.isConfirmed) {
                    dismissNotification(n._id)
                    history.go(0)
                  } else {
                    return
                  }
                })
            }
          }
        }
      })
    })
  }

  const notificationsDates = (notifications) => {
    let dates = {}
    let order = []
    const sorted = notificationsSort(notifications)

    sorted.forEach(n => {
      const date = new Date(n.initiated)
      const stringDate = date.toString().slice(4, 15)

      if(!(stringDate in dates)){
        dates[stringDate] = []
        order.push(stringDate)
      }

      dates[stringDate].push(n)
    })

    dates['order'] = order

    return dates
  }

  React.useEffect(() => {
    if ('order' in notifications && notifications['order'].length > 0) {
      showNotifications()
    }
  }, [notifications])

  React.useEffect(() => {
		const controller = new AbortController()
		
		const fetchData = async() => {
			setLoading(true)

      const avatarPromise = await axios.get(`/api/avatar/${id}`)
      const accountsPromise = await axios.get(`/api/funding-source/${id}`)
      const debitCards = await axios.get(`/api/debit-cards/${id}`)
      const notificationsPromise = await axios.get(`/api/notifications/${id}/3`)
      const upcomingPaymentsPromise = await axios.get(`/api/upcoming-payments/${id}`)

      const notifications = notificationsDates(notificationsPromise.data)
      setNotifications(notifications)
      setAvatar(avatarPromise.data[0] || null)
      setAccounts([
        ...debitCards.data,
        ...accountsPromise.data,
      ])
      setUpcomingPayments(upcomingPaymentsPromise.data)

      setLoading(false)
    }
    
    if(id)
      fetchData()

		return () => {
			controller.abort()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ id ])

  return (
    <div className={'app-body dashboard'}>
      <div className={'dashboard__content'}>
        <Container className={'pt-3 pb-3'}>
          <Col
            lg={'6'}
            className={'dashboard__content__user pt-2 pb-2 pl-3 pr-3 mx-auto'}
            onClick={() => setUserPanelModal(true)}
          >
            <div className={'user-avatar-lg'}>
              {avatar ?
                <Cloudinary
                  cloudName={'dvfifodns'}
                  publicId={avatar.file_name}
                  width={64}
                  height={64}
                  crop={'thumb'}
                  gravity={'face'}
                />           
              :
                <Image roundedCircle src={blankAvatar} />
              }
            </div>
            <div className={'pl-3'}>
                <p className={'m-0'}><b>Hello, {user.first_name + ' ' + user.last_name}</b></p>
                <p className={'m-0'}><small><i>Standard User</i></small></p>
            </div>
            <div className={'ml-auto'}>
              <div className={'dashboard__content__user__qrcode'}>
                <QRCode
                  className={'d-block'}
                  size={40}
                  value={`${process.env.REACT_APP_BASE_URL}/friends?user_id=${user.id}`}
                />
              </div>
            </div>
          </Col>
          <Col
            lg={'6'}
            className={'dashboard__content__menu p-0 mt-4 mx-auto'}
          >
            <p className={'mb-0'}><b>Shortcuts</b></p>
            <ul className={'mb-0 pl-0'}>
              <li>
                <Nav.Link
                  as={Link}
                  to={'/history'}
                >
                  History
                  <ChevronRight
                    className={'ml-auto'}
                    size={16}
                  />
                </Nav.Link>
              </li>
              <li>
                <Nav.Link
                  as={Link}
                  to={'/friends'}
                >
                  Your Friends
                  <ChevronRight
                    className={'ml-auto'}
                    size={16}
                  />
                </Nav.Link>
              </li>
              <li>
                <Nav.Link
                  as={Link}
                  to={'/wallet'}
                >
                  Manage Your Wallet
                  <ChevronRight
                    className={'ml-auto'}
                    size={16}
                  />
                </Nav.Link>
              </li>
              <li>
                <Nav.Link
                  as={Link}
                  to={'/settings'}
                >
                  App/User Settings
                  <ChevronRight
                    className={'ml-auto'}
                    size={16}
                  />
                </Nav.Link>
              </li>
            </ul>
          </Col>
        </Container>
      </div>
      <div className={'dashboard__notifications'}>
        <Container>
          <Row>
            <Col lg={'6'} className={'dashboard__notifications__container mx-auto'}>
              <h2 className={'dashboard__notifications__container__title mb-0'}>
                <span>Upcoming Payments</span>
                <span className="dashboard__notifications__indicator">{!loading && (upcomingPayments.length || 0)}</span>
              </h2>
              {loading ? 
                (
                  <div className={'w-100 pt-5 pb-5 text-center'}>
                    <div className={'mt-5'}>
                      <Spinner animation={'border'} role={'status'} variant={'primary'} />
                    </div>
                  </div>
                ) : (
                  <div className={'w-100 pt-5 pb-5 text-center'}>
                    {upcomingPayments.length ?
                      (
                        upcomingPayments.map((payment, index) => (
                         <Card
                          key={`payment-${index}`}
                          variant={'payment'}
                          index={index}
                          data={{
                            userId: id,
                            notification: payment,
                            accounts: accounts
                          }}
                        />
						
                        ))  
						
                      ) : <div className={'mt-2 mb-2'}>No upcoming payments to display.</div>
                    }
					
                  </div>
                )
              }
            </Col>
          </Row>
        </Container>
      </div>
      <div className={'dashboard__notifications'}>
        <Container>
          <Row>
            <Col lg={'6'} className={'dashboard__notifications__container mx-auto'}>
              <h2 className={'dashboard__notifications__container__title mb-0'}>
                <span>Notifications</span>
                <span className="dashboard__notifications__indicator">{!loading && ('order' in notifications && notifications['order'].length) || 0}</span>
              </h2>
              {loading ? 
                (
                  <div className={'w-100 pt-5 pb-5 text-center'}>
                    <div className={'mt-5'}>
                      <Spinner animation={'border'} role={'status'} variant={'primary'} />
                    </div>
                  </div>
                ) : (
                  <div className={'w-100 pt-5 pb-5 text-center'}>
                    {'order' in notifications && notifications['order'].length ?
                      (
                        notifications['order'].map((date) => {
                          return (
                            <div key={date}>
                              <p className={'dashboard__notifications__container__date mt-3 mb-3'}>{date}</p>
                              {notifications[date].map((n) => {
                                let data = {}
                                index++
                                
                                if(n.type === 'relationship' || n.type === 'relationshipAccepted')
                                  data = {
                                    userId: id,
                                    showFriendModal,
                                    visibleFriendModal,
                                    handleFriendHide,
                                    handleFriendModal,
                                    notification: n,
                                    accounts: accounts
                                  }

                                if(n.type === 'payment')
                                  data = {
                                    userId: id,
                                    notification: n,
                                    accounts: accounts
                                  }

                                if(n.type === 'transfer' || n.type === 'transferAccepted' || n.type === 'transferDeclined')
                                  data = {
                                    userId: id,
                                    showModal,
                                    visibleModal,
                                    handleHide,
                                    handleModal,
                                    notification: n,
                                    monthlyPayment,
                                    accounts: accounts
                                  }
          
                                return (
                                  <Card
                                    key={`${n.type}-${index}`}
                                    variant={n.type}
                                    index={index}
                                    data={data}
                                  />
                                )
                              })}
                            </div>
                          )
                        })
                      ) : <div className={'mt-2 mb-2'}>No notifications to display.</div>
                    }
					<div className={'mt-2 mb-2'}>
					<Button   onClick={() => history.push(`/history/`)}>View All notifications</Button>
					</div>	
                  </div>
                )
              }
            </Col>
          </Row>
        </Container>
      </div>
      <Drawer
        anchor={'top'}
        isOpen={userPanelModal}
        onClose={() => setUserPanelModal(false)}
        showBackdrop
        overrides={{
          Root: {
            style: {
              zIndex: 2
            }
          },
          DrawerContainer: {
            style: {
              backgroundColor: '#64b1ff !important',
              height: '100vh'
            }
          },
          DrawerBody: {
            style: {
              margin: 0
            }
          },
          Close: {
            style: {
              fill: 'white',
              top: '0',
              left: '0',
              height: '50px',
              width: '50px',
              position: 'fixed'
            }
          }
        }}
      >
        <UserPanel />
      </Drawer>
    </div>
  )
}