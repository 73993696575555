import React from 'react'
import { Drawer } from 'baseui/drawer'
import FriendModalPanel from './FriendModalPanel'

export default function FriendModal({ data, index, dismissNotification }) {
  return (
    <Drawer
      anchor={'bottom'}
      isOpen={data.showFriendModal && data.visibleFriendModal === index}
      onClose={data.handleFriendHide && data.handleFriendHide}
      showBackdrop
      overrides={{
        Root: {
          style: {
            zIndex: 2
          }
        },
        DrawerContainer: {
          style: {
            backgroundColor: '#64b1ff !important',
            height: '100vh'
          }
        },
        DrawerBody: {
          style: {
            margin: 0
          }
        },
        Close: {
          style: {
            fill: 'white',
            top: '0',
            left: '0',
            height: '50px',
            width: '50px',
            position: 'fixed'
          }
        }
      }}
    >
      <FriendModalPanel
        data={data}
        dismissNotification={dismissNotification}
      />
    </Drawer>
  )
}