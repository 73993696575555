import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Button, Container, Image } from 'react-bootstrap'
import { ArrowLeftRight, HouseDoor, Gear, People } from 'react-bootstrap-icons'
import Menu from '../Menu'
import Transactions from '../Transactions'
import { store } from '../../helpers/Store'
import menuButton from '../../assets/menu-button.png'

export default function Footer(props) {
  const selected = props.selected
  const globalState = useContext(store)
  const { state, dispatch } = globalState

  return (
    <footer className={'footer'}>
      <Container className={'footer__container'}>
        <Button
          variant={'link'}
          className={'footer__link'}
          as={Link}
          to={'/pari'}
        >
          <HouseDoor
            className={selected === 'landing' ? 'selected' : ''}
            size={32}
          />
        </Button>
        <Button
          variant={'link'}
          className={'footer__link'}
          as={Link}
          to={'/history'}
        >
          <ArrowLeftRight
            className={selected === 'history' ? 'selected' : ''}
            size={32}
          />
        </Button>
        <Button
          variant={'link'}
          className={'footer__button'}
          onClick={() => dispatch({type: 'menu'})}
        >
          {!state['menu'] && 
            <Image src={menuButton} />
          }
        </Button>
        <Button
          variant={'link'}
          className={'footer__link'}
          as={Link}
          to={'/friends'}
          >
          <People
            className={selected === 'friends' ? 'selected' : ''}
            size={32}
          />
        </Button>
        <Button
          variant={'link'}
          className={'footer__link'}
          as={Link}
          to={'/settings'}
        >
          <Gear
            className={selected === 'settings' ? 'selected' : ''}
            size={32}
          />
        </Button>
      </Container>
      <Menu />
      <Transactions
        type={'lend'}
      />
      <Transactions
        type={'borrow'}
      />
      <Transactions
        type={'buySell'}
      />
    </footer>
  )
}