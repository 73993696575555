import React from 'react'
import { Button, Col, Row, Spinner } from 'react-bootstrap'

export default function Step3(props) {
  const { loading, purchase, view, paymentMethods, SelectedFriendName, changeStep, sendTransfer } = props

  return (
    <div>
      <h2 className={'subtitle'}>Transaction Summary</h2>
      <dl className={'buysell__resume mt-4'}>
        <dt className={'pt-3 border-top'}>Sale Price:</dt>
        <dd className={'pb-1'}>{purchase['amount-mask']}</dd>
        <dt className={'mt-3'}>Down Payment Amount:</dt>
        <dd>{purchase['down-mask']}</dd>
        <dt className={'pt-1'}>Pari Fees:</dt>
        <dd className={'pb-1'}>{purchase['fees-mask']}</dd>
        <dt className={'pt-1'}>Total Loan Amount:</dt>
        <dd className={'pb-3 border-bottom'}><big>{purchase['total-mask']}</big></dd>
        <dt className={'mt-3'}>Type:</dt>
        <dd>{ view === 0 ? 'Buy' : 'Sell' }</dd>
        <dt className={'mt-3'}>Interest Rate:</dt>
        <dd>{purchase['rate']}%</dd>
        <dt className={'mt-3'}>Term Length (Months):</dt>
        <dd>{purchase['term']}</dd>
        <dt className={'mt-3'}>{ view === 0 ? 'Repayment Source:' : 'Account to Receive Payments:' }</dt>
        <dd>{paymentMethods[purchase['method']].bankName ? `${paymentMethods[purchase['method']].bankName} ` : ''} {paymentMethods[purchase['method']].nickname} {paymentMethods[purchase['method']].identifier ? `...(${paymentMethods[purchase['method']].identifier})` : ''}</dd>
        <dt className={'mt-3'}>{ view === 0 ? 'Request from:' : 'Offer to:' }</dt>
        <dd>
          <SelectedFriendName />
        </dd>
        {purchase['notes'] ?
          <dt className={'mt-2'}>Product or Service:</dt>
          :
          null
        }
        {purchase['notes'] ?
          <dd>{purchase['notes']}</dd>
          :
          null
        }
      </dl>
      <Row>
        <Col xs={'12'} className={'mt-4'}>
          <Button
            variant={'secondary'}
            className={'w-100'}
            size={'lg'}
            onClick={sendTransfer}
          >
            { loading ? <Spinner animation={'border'} role={'status'} variant={'light'} /> : view === 0 ? 'Offer to Buy' : 'Offer to Sell' }
          </Button>
        </Col>
        <Col xs={'12'} className={'mt-4'}>
          <Button
            onClick={e => changeStep(e, 2)}
            variant={'light'}
            className={'w-100'}
            size={'lg'}
          >
            Previous
          </Button>
        </Col>
      </Row>
    </div>
  )
}