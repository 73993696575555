import React from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'

import Login from '../containers/Login'
import ForgotPassword from '../containers/ForgotPassword'
import SignUp from '../containers/SignUp'
import Walkthrough from '../containers/Walkthrough'
import Landing from '../containers/Landing'
import History from '../containers/History'
import Friends from '../containers/Friends'
import FriendsDetails from '../containers/FriendsDetails'
import Settings from '../containers/Settings'
import Wallet from '../containers/Wallet'
import NewFeatures from '../containers/NewFeatures'
import AddPayment from '../containers/AddPayment'
import PlaidPayment from '../containers/PlaidPayment'
import DebitPayment from '../containers/DebitPayment'
import Terms from '../components/Terms';
import ManualPayment from '../components/ManualPayment'

import PrivateRoute from './privateRoute'
import PublicRoute from './publicRoute'

export default () => (
	<BrowserRouter>
		<Switch>
			<PublicRoute component={false} path={'/'} exact />
			<PublicRoute component={Terms} path={'/terms'} />
			<PublicRoute component={Login} path={'/login'} />
			<PublicRoute component={SignUp} path={'/sign-up'} />
			<PublicRoute component={ForgotPassword} path={'/forgot-password'} />
			<PrivateRoute component={Walkthrough} path={'/walkthrough'} />
			<PrivateRoute component={Landing} path={'/pari'} />
			<PrivateRoute component={History} path={'/history'} />
			<PrivateRoute component={Friends} path={'/friends'} exact />
			<PrivateRoute component={ManualPayment} path={'/manual-payment/:id'} />
			<PrivateRoute component={FriendsDetails} path={'/friends/:id'} />
			<PrivateRoute component={Settings} path={'/settings'} />
			<PrivateRoute component={Wallet} path={'/wallet'} />
			<PrivateRoute component={NewFeatures} path={'/new-features'} />
			<PrivateRoute component={AddPayment} path={'/payment-method'} />
			<PrivateRoute component={PlaidPayment} path={'/payment-method-plaid/:userId'} />
			<PrivateRoute component={DebitPayment} path={'/payment-method-debit/:userId'} />
		</Switch>
	</BrowserRouter>
)