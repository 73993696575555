import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Image, Spinner } from 'react-bootstrap'
import axios from 'axios'
import Swal from 'sweetalert2'
import { Image as Cloudinary } from 'cloudinary-react'
import blankAvatar from '../../assets/blank-avatar.png'
import './styles.scss'

export default function UserPanel({ id, userId, handleClose }) {
  const [ avatar, setAvatar ] = useState(null)
  const [ details, setDetails ] = useState(null)
  const [ loading, setLoading ] = useState(false)
  const [ valid, setValid ] = useState(null)

  const handleConfirm = async() => {
    try{
      await axios.post(`/api/relationships/${userId}/${id}`)

      await Swal.fire({
        icon: 'success',
        title: 'Success!',
        html: 'Friend request sent!'
      })
    }catch(err){
      await Swal.fire({
        icon: 'error',
        title: 'Error!',
        html: 'response' in err ? err.response.data : err
      })
    }

    handleClose()
  }

  useEffect(() => {
		const controller = new AbortController()
		
		const fetchData = async() => {
			setLoading(true)

      const userPromise = await axios.get(`/api/users/${id}`)
      const avatarPromise = await axios.get(`/api/avatar/${id}`)
      
      if(userPromise && userPromise.data.length){
        setDetails(userPromise.data[0])
        setAvatar(avatarPromise.data[0] || null)
        setValid(true)
      }else{
        setValid(false)
      }

      setLoading(false)
    }
    
    if(id)
      fetchData()

		return () => {
			controller.abort()
    }
  }, [ id ])

  return (
    <Container className={'userpanel'}>
      <Col sm={'6'} className={'userpanel__flex mx-auto pt-3 pb-5'}>
        <div className={'mt-4'}>
          <h1 className={'title'}>Add Friend</h1>
        </div>
        {loading ? (
          <div className={'w-100 mt-auto mb-auto text-center'}>
              <Spinner animation={'border'} role={'status'} variant={'primary'} />
          </div>
        ) : (
          <div className={'mt-auto mb-auto'}>
            {valid ? (
              <div>
                <div className={'userpanel__avatar'}>
                  {avatar ?
                    <Cloudinary
                      cloudName={'dvfifodns'}
                      publicId={avatar.file_name}
                      width={200}
                      height={200}
                      crop={'thumb'}
                      gravity={'face'}
                    />           
                  :
                    <Image roundedCircle src={blankAvatar} />
                  }
                </div>
                <h4 className={'mt-4'}>{details.first_name + ' ' + details.last_name}</h4>
                <h6 className={'mt-2 text-primary'}><em>Standard User</em></h6>
                <h6 className={'mt-2'}>{details.email}</h6>
              </div>
            ) : (
              <div>
                <h4>User not found!</h4>
              </div>
            )}
          </div>
        )}
        {!loading && (
          <Button
            type={'link'}
            variant={'secondary'}
            className={'w-100 mt-auto'}
            size={'lg'}
            onClick={valid ? handleConfirm : handleClose}
          >
            {valid ? 'Confirm' : 'Close'}
          </Button>
        )}
      </Col>
    </Container>
  )
}