import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Button, Container, Col, Spinner } from 'react-bootstrap'
import Content from '../../components/Content'
import FriendsAdd from '../../components/FriendsAdd'
import FriendsList from '../../components/FriendsList'
import FriendsSearch from '../../components/FriendsSearch'
import PageTitle from '../../components/PageTitle'
import User from '../../helpers/User'
import './styles.scss'

export default function Friends() {
  const search = window.location.search
  const params = new URLSearchParams(search)
  const paramId = params.get('user_id')
  const userData = new User().getData()
  const userId = userData.user.id

  const [ friendsView, setFriendsView ] = useState(0)
  const [ friends, setFriends ] = useState([])
  const [ loading, setLoading ] = useState(false)

  useEffect(() => {
		const controller = new AbortController()
		
		const fetchData = async() => {
			setLoading(true)

      const friendsPromise = await axios.get(`/api/friends/${userId}`)

      setFriends(friendsPromise.data)
      setLoading(false)
    }
    
    if(userId)
      fetchData()

		return () => {
			controller.abort()
    }
  }, [ userId ])

  return (
    <Content page={'friends'}>
      <PageTitle title={'Friends'} />
      <div className={'app-body app-body--page'}>
        <Container className={'pt-3 pb-3'}>
          <Col
            lg={'6'}
            className={'mx-auto p-0'}
          >
            {friendsView !== 1 && (
              <div className={'pt-1'}>
                <Button
                  size={'sm'}
                  variant={friendsView === 0 ? 'primary' : 'outline-primary'}
                  className={'pl-3 pr-3 mr-2'}
                  onClick={() => setFriendsView(0)}
                >
                  Friends
                </Button>
                {/*<Button
                  size={'sm'}
                  variant={friendsView === 1 ? 'primary' : 'outline-primary'}
                  className={'pl-3 pr-3 mr-2'}
                  onClick={() => setFriendsView(1)}
                >
                  Facebook
                </Button>*/}
                <Button
                  size={'sm'}
                  variant={friendsView === 2 ? 'primary' : 'outline-primary'}
                  className={'pl-3 pr-3'}
                  onClick={() => setFriendsView(2)}
                >
                  Search
                </Button>
              </div>
            )}
            {loading ?
              (
                <div className={'w-100 pt-5 pb-5 text-center'}>
                  <div className={'mt-5'}>
                    <Spinner animation={'border'} role={'status'} variant={'primary'} />
                  </div>
                </div>
              )
            : (
                <div>
                  {friendsView === 0 && (
                    <FriendsList friends={friends} />
                  )}
                  {/*friendsView === 1 ? !friends.find(f => f._id === paramId) || (friends.find(f => f._id === paramId) && friends.find(f => f._id === paramId).length > 0 && friends.find(f => f._id === paramId).status === 0) ? (
                    <Modal className={'friend-modal'} show={true}>
                      <Modal.Body className={'w-100 m-0 p-0 text-center'}>
                        <div className={'w-100 friend-result mb-3 pb-3'}>I want to...</div>
                        <div className={'w-100 friend-result mb-3 pb-3'}><NavLink onClick={() => addFriend(paramId)}>Add User to Friends</NavLink></div>
                      </Modal.Body>
                    </Modal>
                  ) : friends.find(f => f._id === paramId) && friends.find(f => f._id === paramId).status === 1 ? (
                      <Modal className={'friend-modal'} show={true}>
                        <Modal.Body className={'w-100 m-0 p-0 text-center'}>
                          <div className={'w-100 friend-result mb-3 pb-3'}>I want to...</div>
                          <div className={'w-100 friend-result mb-3 pb-3'}><NavLink href={`/lend?receiver=${friends.find(f => f._id === paramId).userOne === userId ? friends.find(f => f._id === paramId).userTwo : friends.find(f => f._id === paramId).userOne}`}>Lend to {friends.find(f => f._id === paramId) && `${friends.find(f => f._id === paramId).first_name} ${friends.find(f => f._id === paramId).last_name}`}</NavLink></div>
                        </Modal.Body>
                      </Modal>
                    ) : ( <div className={'mt-5'}>This user cannot be shown.</div> )
                    : null
                  */}
                  {friendsView === 2 && (
                    <FriendsSearch userId={userId} />
                  )}
                </div>
              )
            }
          </Col>
        </Container>
      </div>
      {paramId && (
        <FriendsAdd
          id={paramId}
          userId={userId}
        />
      )}
    </Content>
  );
}