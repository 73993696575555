import React from 'react'
import { Button, Form } from 'react-bootstrap'
import IntlCurrencyInput from 'react-intl-currency-input'
import NumberFormat from 'react-number-format'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { ChevronRight, QuestionCircle } from 'react-bootstrap-icons'

export default function Step1(props) {
  const { loan, payment, term, view, changeStep, handleViewChange,handleViewgraceperiod, handleMoneyChange, handlePercentageChange, handleChange,grace_period, graceperiodChange, graceperiodchecked, gracePeriod, enableloanfee, enableloanfeeChange, handleLoanfeeChange,loan_fee } = props
  return (
    <Form onSubmit={e => changeStep(e, 2)}>
      <h2 className={'subtitle'}>Transaction Values</h2>
	  <Form.Group className={'mt-4'} controlId={'type'}>
        <Form.Label>Type:</Form.Label>
        <Form.Control
          as={'select'}
          className={'white'}
          onChange={e => handleViewChange(e)}
        >
          <option value={0}>By Term</option>
          <option value={1}>By Payment</option>
        </Form.Control>
      </Form.Group>
	  
	  <Form.Group className={'mt-2'} controlId={'amount'}>
        <Form.Label>Amount (max $5000.00):</Form.Label>
        <IntlCurrencyInput
          required
          className={'form-control white'}
          type={'tel'}
          name={'amount'}
          value={loan['amount']}
          onChange={handleMoneyChange}
        />
      </Form.Group>
	  
	  
	 <Form.Group as={Row} className="mb-3 pl-2" controlId="grace_period">
        <Form.Label column sm="10" xs="10" className={'pl-4'}>
          Enable Grace Period
		<OverlayTrigger
			delay={{ hide: 450, show: 300 }}
			overlay={(props) => (
			  <Tooltip {...props}>
				A grace period is a time period granted on a loan during which the borrower does not have to pay the lender any monies toward the loan, and the borrower does not incur any penalties for not paying.
			  </Tooltip>
			)}
			placement="bottom"
		  ><QuestionCircle color="white" size={24} className={'pl-1'} />
		  </OverlayTrigger>
	  
        </Form.Label>
        <Col sm="2" xs="2">
           <Form.Check 
			type="switch"
			id="custom-switch"
			label=""
			name={'grace_period_checked'}
		    defaultChecked={graceperiodchecked}

            value={loan['grace_period_checked']}
			onChange={e => graceperiodChange(e)}
		  />
        </Col>
      </Form.Group>
	  {graceperiodchecked == 1 ?
	  <Form.Group className={'mt-4'} controlId={'grace_period'}>
        <Form.Label>Grace period (Days):</Form.Label>
        <Form.Control
          as={'select'}
          className={'white'}
          onChange={e => handleViewgraceperiod(e)}
        >
          <option value={0} selected={gracePeriod == 0}>0 days</option>
          <option value={30} selected={gracePeriod == 30}>30 days </option>
          <option value={60} selected={gracePeriod == 60}>60 days</option>
          <option value={90} selected={gracePeriod == 90}>90 days</option>
        </Form.Control>
      </Form.Group>	: '' }
	  
	  <Form.Group as={Row} className="mb-3 pl-2" controlId="enableloanfee">
        <Form.Label column sm="10" xs="10" className={'pl-4'}>
          Enable Loan Fee
		  <OverlayTrigger
			delay={{ hide: 450, show: 300 }}
			overlay={(props) => (
			  <Tooltip {...props}>
				A loan fee is charged to a borrower for processing a loan. Loan fees are intended to pay for the costs of the process of loan approval by the lender. 100% of the loan fee goes to the lender
			  </Tooltip>
			)}
			placement="bottom"
		  ><QuestionCircle color="white" size={24} className={'pl-1'} />
		  </OverlayTrigger>
        </Form.Label>
        <Col sm="2" xs="2">
           <Form.Check 
			type="switch"
			id="enable-loan-fee"
			label=""
			name={'enableloanfee'}
		    defaultChecked={enableloanfee}

            value={loan['enableloanfee']}
			onChange={e => enableloanfeeChange(e)}
		  />
        </Col>
      </Form.Group>
	  
      {enableloanfee == 1 ?
		 <Form.Group className={'mt-2'} controlId={'rate'}>
        <Form.Label>Loan Fee ($) :</Form.Label>
        <IntlCurrencyInput
          
          className={'form-control white'}
          type={'tel'}
          name={'loan_fee'}
          value={loan_fee}
		  onChange={handleLoanfeeChange}

        />
		
      </Form.Group>
	  :
      <Form.Group className={'mt-2'} controlId={'rate'}>
        <Form.Label>Interest Rate (%):</Form.Label>
        <NumberFormat
          required
          allowNegative={false}
          isNumericString={true}
          className={'form-control white'}
          suffix={'%'}
          type={'tel'}
          name={'rate'}
          value={loan['rate']}
          onValueChange={(values) => handlePercentageChange('rate', values.value)}
        />
      </Form.Group>
	  }
	  
      {view === 0 ?
        <Form.Group className={'mt-2'} controlId={'term'}>
          <Form.Label>Term Length (Months):</Form.Label>
          <Form.Control
            required  
            className={'white'}
            type={'number'}
            inputMode={'numeric'}
            name={'term'}
            value={loan['term']}
            min={'1'}
            onChange={handleChange}
          />
        </Form.Group>
      :
        <Form.Group className={'mt-2'} controlId={'payment'}>
          <Form.Label>Monthly Payment:</Form.Label>
          <IntlCurrencyInput
            required
            className={'form-control white'}
            type={'tel'}
            name={'payment'}
            value={loan['payment']}
            onChange={handleMoneyChange}
          />
        </Form.Group>
      }
      <div className={'mt-4 pl-0 pr-0'}>
        {(payment || term) ?
          <div>
            {view === 0 ?
              <div>
                <p>MONTHLY PAYMENT</p>
                <p>$ {payment.toFixed(2)}</p>
              </div>
            :
              <div>
                <p>TERM (MONTHS)</p>
                <p>{term}</p>  
              </div>
            }
          </div>
        : null}
      </div>
      <div className={'mt-4'}>
        <Button
          type={'submit'}
          variant={'secondary'}
          className={'w-100'}
          size={'lg'}
        >
          Continue
        </Button>
      </div>
    </Form>
  )
}