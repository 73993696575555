import React from 'react'
import { Button, Col, Container, Image } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import Swal from 'sweetalert2'
import { Image as Cloudinary } from 'cloudinary-react'
import blankAvatar from '../../../assets/blank-avatar.png'

export default function UserPanel({ data, dismissNotification }) {
  const friend = data.notification.sender
  const history = useHistory()

  const acceptRequest = async(friendId, userId) => {
    try{
      await axios.put(`/api/relationships/${friendId}/${userId}/accept`)
      await dismissNotification()

      await Swal.fire({
        icon: 'success',
        title: 'Success!',
        html: 'Friend successfully add!'
      })
    }catch(err){
      await Swal.fire({
        icon: 'error',
        title: 'Error!',
        html: 'response' in err ? err.response.data : err
      })
    }

    history.go(0)
  }

  return (
    <Container className={'userpanel'}>
      <Col sm={'6'} className={'userpanel__flex mx-auto pt-3 pb-5'}>
        <div className={'mt-4'}>
          <h1 className={'title'}>{friend.first_name} want to be your friend!</h1>
        </div>
        <div className={'mt-auto mb-auto'}>
          <div className={'userpanel__avatar'}>
            {friend.avatar ?
              <Cloudinary
                cloudName={'dvfifodns'}
                publicId={friend.avatar.file_name}
                width={200}
                height={200}
                crop={'thumb'}
                gravity={'face'}
              />           
            :
              <Image roundedCircle src={blankAvatar} />
            }
          </div>
          <h4 className={'mt-4'}>{friend.first_name + ' ' + friend.last_name}</h4>
          <h6 className={'mt-2 text-primary'}><em>Standard User</em></h6>
          <h6 className={'mt-2'}>{friend.email}</h6>
        </div>
        <Button
          type={'link'}
          variant={'secondary'}
          className={'w-100 mt-auto'}
          size={'lg'}
          onClick={() => acceptRequest(friend._id, data.userId)}
        >
          Accept Request
        </Button>
        <Button
          type={'link'}
          variant={'danger'}
          className={'w-100 mt-3'}
          size={'lg'}
          onClick={data.handleFriendHide && data.handleFriendHide}
        >
          Decline Request
        </Button>
      </Col>
    </Container>
  )
}