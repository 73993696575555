import React, {useState} from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button, Container, Col, ListGroup } from 'react-bootstrap'
import { ListUl, BoxArrowInRight, ChevronRight, ClipboardData, InfoCircle, QuestionCircle, Briefcase, Wallet, PeopleFill } from 'react-bootstrap-icons'
import { Drawer } from 'baseui/drawer'
import UserPanel from '../UserPanel'
import User from '../../helpers/User'
import './styles.scss'

export default function SettingsMenu() {
  const history = useHistory()
  const [ userPanelModal, setUserPanelModal ] = useState(false)

  const logout = (e) => {
    e.preventDefault()

    new User().removeData()
    history.push('/login')
  }  
  const openFaqPage = () => {
	window.open('https://www.pariapp.com/faq', '_blank');

  }
  
  return (
    <div className={'app-body app-body--page'}>
      <Container className={'pt-3 pb-3'}>
        <Col
          lg={'6'}
          className={'mx-auto p-0'}
        >
          <ListGroup
            variant={'flush'}
            className={'settings-menu'}
          >
            <ListGroup.Item className={'p-0'}>  
              <Button
                className={'pt-3 pb-3 pl-0 pr-0'}
                variant={'link'}
                as={Link}
                to={'/wallet'}
              >
                <Wallet
                  className={'mr-3'}
                  size={32}
                />
                Wallet
                <ChevronRight
                  className={'ml-auto'}
                  size={24}
                />
              </Button>
            </ListGroup.Item>
            <ListGroup.Item className={'p-0'}>
              <Button
                className={'pt-3 pb-3 pl-0 pr-0'}
                variant={'link'}
                onClick={() => setUserPanelModal(true)}
              >
                <PeopleFill
                  className={'mr-3'}
                  size={32}
                />
                Profile
                <ChevronRight
                  className={'ml-auto'}
                  size={24}
                />
              </Button>
            </ListGroup.Item>
			<ListGroup.Item className={'p-0'}>
              <Button
                className={'pt-3 pb-3 pl-0 pr-0'}
                variant={'link'}
                as={Link}
                to={'/new-features'}
              >
                <ListUl
                  className={'mr-3'}
                  size={32}
                />
                New Features
                <ChevronRight
                  className={'ml-auto'}
                  size={24}
                />
              </Button>
            </ListGroup.Item>
			<ListGroup.Item className={'p-0'}>
              <Button
                className={'pt-3 pb-3 pl-0 pr-0'}
                variant={'link'}
                onClick={() => openFaqPage ()}
              >
                <QuestionCircle
                  className={'mr-3'}
                  size={32}
                />
                FAQ
                <ChevronRight
                  className={'ml-auto'}
                  size={24}
                />
              </Button>
            </ListGroup.Item>
			
            {/* <ListGroup.Item className={'p-0'}>
              <Button
                className={'pt-3 pb-3 pl-0 pr-0'}
                variant={'link'}
                disabled
              >
                <ClipboardData
                  className={'mr-3'}
                  size={32}
                />
                Report
                <ChevronRight
                  className={'ml-auto'}
                  size={24}
                />
              </Button>
            </ListGroup.Item>
            <ListGroup.Item className={'p-0'}>
              <Button
                className={'pt-3 pb-3 pl-0 pr-0'}
                variant={'link'}
                disabled
              >
                <QuestionCircle
                  className={'mr-2'}
                  size={32}
                />
                Help &amp; Support
                <ChevronRight
                  className={'ml-auto'}
                  size={24}
                />
              </Button>
            </ListGroup.Item>
            <ListGroup.Item className={'p-0'}>
              <Button
                className={'pt-3 pb-3 pl-0 pr-0'}
                variant={'link'}
                disabled
              >
                <InfoCircle
                  className={'mr-2'}
                  size={32}
                />
                Terms &amp; Policy
                <ChevronRight
                  className={'ml-auto'}
                  size={24}
                />
              </Button>
            </ListGroup.Item>
            <ListGroup.Item className={'p-0'}>
              <Button
                className={'pt-3 pb-3 pl-0 pr-0'}
                variant={'link'}
                disabled
              >
                <Briefcase
                  className={'mr-2'}
                  size={32}
                />
                About Us
                <ChevronRight
                  className={'ml-auto'}
                  size={24}
                />
              </Button>
            </ListGroup.Item> */}
            <ListGroup.Item className={'p-0'}>
              <Button
                className={'pt-3 pb-3 pl-0 pr-0'}
                variant={'link'}
                onClick={logout}
              >
                <BoxArrowInRight
                  className={'mr-3'}
                  size={32}
                />
                Log Out
                <ChevronRight
                  className={'ml-auto'}
                  size={24}
                />
              </Button>
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Container>
      <Drawer
        anchor={'top'}
        isOpen={userPanelModal}
        onClose={() => setUserPanelModal(false)}
        showBackdrop
        overrides={{
          Root: {
            style: {
              zIndex: 2
            }
          },
          DrawerContainer: {
            style: {
              backgroundColor: '#64b1ff !important',
              height: '100vh'
            }
          },
          DrawerBody: {
            style: {
              margin: 0
            }
          },
          Close: {
            style: {
              fill: 'white',
              top: '0',
              left: '0',
              height: '50px',
              width: '50px',
              position: 'fixed'
            }
          }
        }}
      >
        <UserPanel />
      </Drawer>
    </div>
  )
}