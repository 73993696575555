import React from 'react'
import { BaseProvider, LightTheme } from 'baseui'
import { Client as Styletron } from 'styletron-engine-atomic'
import { Provider as StyletronProvider } from 'styletron-react'
import Router from './router'
import { StateProvider } from './helpers/Store'
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.scss'

const engine = new Styletron()

export default function App() {
  return (
    <div className={'App'}>
      <StateProvider>
        <StyletronProvider value={engine}>
          <BaseProvider theme={LightTheme}>
            <Router />
          </BaseProvider>
        </StyletronProvider>
      </StateProvider>
    </div>
  )
}
