import React from 'react'
import { Button, Form } from 'react-bootstrap'
import IntlCurrencyInput from 'react-intl-currency-input'
import NumberFormat from 'react-number-format'

export default function Step1(props) {
  const { purchase, payment, changeStep, handleViewChange, handleMoneyChange, handlePercentageChange, handleChange } = props

  return (
    <Form onSubmit={e => changeStep(e, 2)}>
      <h2 className={'subtitle'}>Transaction Values</h2>
      <Form.Group className={'mt-4'} controlId={'type'}>
        <Form.Label>Type:</Form.Label>
        <Form.Control
          as={'select'}
          className={'white'}
          onChange={e => handleViewChange(e)}
        >
          <option value={0}>Buy</option>
          <option value={1}>Sell</option>
        </Form.Control>
      </Form.Group>
      <Form.Group className={'mt-2'} controlId={'amount'}>
        <Form.Label>Sale Price:</Form.Label>
        <IntlCurrencyInput
          required
          className={'form-control white'}
          type={'tel'}
          name={'amount'}
          value={purchase['amount']}
          onChange={handleMoneyChange}
        />
      </Form.Group>
      <Form.Group className={'mt-2'} controlId={'rate'}>
        <Form.Label>Interest Rate (%):</Form.Label>
        <NumberFormat
          required
          allowNegative={false}
          isNumericString={true}
          className={'form-control white'}
          suffix={'%'}
          type={'tel'}
          name={'rate'}
          value={purchase['rate']}
          onValueChange={(values) => handlePercentageChange('rate', values.value)}
        />
      </Form.Group>
      <Form.Group className={'mt-2'} controlId={'down'}>
        <Form.Label>Down Payment Amount:</Form.Label>
        <IntlCurrencyInput
          required
          className={'form-control white'}
          type={'tel'}
          name={'down'}
          value={purchase['down']}
          onChange={handleMoneyChange}
        />
      </Form.Group>
      <Form.Group className={'mt-2'} controlId={'term'}>
        <Form.Label>Term Length (Months):</Form.Label>
        <Form.Control
          required  
          className={'white'}
          type={'number'}
          name={'term'}
          value={purchase['term']}
          min={'1'}
          onChange={handleChange}
        />
      </Form.Group>
      <div className={'mt-4 pl-0 pr-0'}>
        { payment ?
          <div>
            <small>MONTHLY PAYMENT ($)</small>
            <p>$ {payment.toFixed(2)}</p>
          </div>
        :
          null
        }
      </div>
      <div className={'mt-4'}>
        <Button
          type={'submit'}
          variant={'secondary'}
          className={'w-100'}
          size={'lg'}
        >
          Continue
        </Button>
      </div>
    </Form>
  )
}