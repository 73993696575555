import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios'
import { Button, Col, Container, Form, Image, Nav, Spinner } from 'react-bootstrap'
import Swal from 'sweetalert2'
import moment from 'moment'
import logo from '../../assets/logo.png'
import './styles.scss'

const icons = {
  'user': require('../../assets/user.png'),
  'padlock': require('../../assets/padlock.png')
}

export default function ForgotPassword() {
  const [tokenId, setTokenId] = useState('')
  const [email, setEmail] = useState('')
  const [pass, setPass] = useState('')
  const [passConfirm, setPassConfirm] = useState('')
  const [tokenValid, setTokenValid] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isValid, setIsValid] = useState(false);
  const history = useHistory()
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const handleSubmit = async(e) => {
    console.log('submitting email')
    e.preventDefault()
    
    setLoading(true)

    try{
      await axios.post('/api/reset-password', { email }).then(async(resp) => {
        await Swal.fire({
          icon: 'success',
          title: 'Success!',
          html: 'Please check your email for reset instructions.'
        })
        history.push('/login')
      })
    } catch(err){
      await Swal.fire({
        icon: 'error',
        title: 'Error!',
        html: err.response && err.response.data || err
      })

      setLoading(false)
    }
  }

  const handleSubmitPassword = async(e) => {
    e.preventDefault()
    
    setLoading(true)

    try{
      await axios.post('/api/update-password', { email, pass }).then(async(resp) => {
        await Swal.fire({
          icon: 'success',
          title: 'Success!',
          html: 'Password reset successfully!'
        })
        history.push('/login')
      })
    } catch(err){
      await Swal.fire({
        icon: 'error',
        title: 'Error!',
        html: err.response && err.response.data || err
      })

      setLoading(false)
    }
  }

  React.useEffect(() => {
    setTokenId(params.token)
  }, [])

  React.useEffect(() => {
    (async () => {
      if (tokenId && tokenId.length === 24) {
        const resp = await axios.get(`/api/reset-password/${tokenId}`)
        if (moment(resp.data.expires) >= moment()) {
          setTokenValid(true)
        } else {
          await Swal.fire({
            icon: 'error',
            title: 'Error!',
            html: 'This token is invalid or expired! Please try resetting your password again.'
          })
        }
      }
    })()
  }, [tokenId])

  if (tokenValid) {
    return (
      <div className={'forgot-password'}>
        <Container>
          <Col sm={'6'} className={'mx-auto'}>
            <div className={'pt-5 pb-5'}>
              <Image
                src={logo}
                className={'main-logo'}
              />
            </div>
            <Form onSubmit={e => handleSubmitPassword(e)}>
              <h2 className={'subtitle'}>Reset Password</h2>
              <div className={'input-icon mt-4'}>
                  <label htmlFor={'email'}>
                    <Image src={icons['user']} />
                  </label>
                  <Form.Control
                    id={'email'}
                    autoComplete={'email'}
                    type={'email'}
                    placeholder={'Email'}
                    required={true}
                    onChange={(e) => setEmail(e.target.value)}
                  />
              </div>
              <div className={'input-icon mt-4'}>
                <label htmlFor={'password'}>
                  <Image src={icons['padlock']} />
                </label>
                <Form.Control
                  id={'password'}
                  autoComplete={'current-password'}
                  type={'password'}
                  placeholder={'New Password'}
                  required={true}
                  onChange={(e) => setPass(e.target.value)}
                />
              </div>
              <div className={'input-icon mt-4'}>
                <label htmlFor={'confirm-password'}>
                  <Image src={icons['padlock']} />
                </label>
                <Form.Control
                  id={'confirm-password'}
                  autoComplete={'confirm-password'}
                  type={'password'}
                  placeholder={'Confirm New Password'}
                  required={true}
                  onChange={(e) => setPassConfirm(e.target.value)}
                />
              </div>
              <div className={'mt-4'}>
                <Button
                  className={'w-100'}
                  size={'lg'}
                  type={'submit'}
                  disabled={loading || pass !== passConfirm}
                >
                  {loading ?
                      <Spinner
                        as={'span'}
                        animation={'border'}
                      />
                    :
                      <span>RESET PASSWORD</span>
                  }
                </Button>
              </div>
            </Form>
            <div className={'pt-5 pb-5'}>
              Don't have an account?
              <Button
                className={'w-100 mt-4'}
                variant={'outline-primary'}
                size={'lg'}
                as={Link}
                to={'/sign-up'}
                disabled={loading}
              >
                SIGN UP
              </Button>
            </div>
          </Col>
        </Container>
      </div>
    )
  }

  return (
    <div className={'forgot-password'}>
      <Container>
        <Col sm={'6'} className={'mx-auto'}>
          <div className={'pt-5 pb-5'}>
            <Image
              src={logo}
              className={'main-logo'}
            />
          </div>
          <Form onSubmit={e => handleSubmit(e)}>
            <h2 className={'subtitle'}>Request Password Reset Email</h2>
            <div className={'input-icon mt-4'}>
              <label htmlFor={'email'}>
                <Image src={icons['user']} />
              </label>
              <Form.Control
                id={'email'}
                autoComplete={'email'}
                type={'email'}
                placeholder={'Email'}
                required={true}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className={'mt-4'}>
              <Button
                className={'w-100'}
                size={'lg'}
                type={'submit'}
                disabled={loading}
              >
                {loading ?
                    <Spinner
                      as={'span'}
                      animation={'border'}
                    />
                  :
                    <span>REQUEST EMAIL</span>
                }
              </Button>
            </div>
          </Form>
          <div className={'pt-5 pb-5'}>
            Don't have an account?
            <Button
              className={'w-100 mt-4'}
              variant={'outline-primary'}
              size={'lg'}
              as={Link}
              to={'/sign-up'}
              disabled={loading}
            >
              SIGN UP
            </Button>
          </div>
        </Col>
      </Container>
    </div>
  )
}