import React from 'react'
import { Card, Col, Image, Row } from 'react-bootstrap'
import { Image as Cloudinary } from 'cloudinary-react'
import blankAvatar from '../../../assets/blank-avatar.png'
import moment from 'moment'

export default function FriendCard(props) {
  const { userId, avatar, data, index, dismissed } = props
  const isSender = data.notification.sender._id === userId
  const notificationTime = moment(data.notification.initiated).format('hh:mm A')

  return (
    <Card className={'card'} onClick={() => !isSender && data.handleFriendModal && data.handleFriendModal(index)}>
      <Row className={'pl-3 pr-3'}>
        <Col xs={'3'} className={'card__avatar user-avatar-md p-0'}>
          {avatar ?
            <Cloudinary
              cloudName={'dvfifodns'}
              publicId={avatar.file_name}
              width={40}
              height={40}
              crop={'thumb'}
              gravity={'face'}
            />           
          :
            <Image roundedCircle src={blankAvatar} />
          }
        </Col>
        <Col xs={'9'} className={`card__text ${!isSender && !dismissed ? 'card__text--bold card__text--cursor' : ''} p-0`}>
          <small>{notificationTime}</small>
          <small className={'green'}>New Friend Request</small>
          {isSender ?
            <p className={'m-0'}><b>You</b> asked <b>{data.notification.receiver.first_name} {data.notification.receiver.last_name}</b> to be friends.</p>
          :
            <p className={'m-0'}><b>{data.notification.sender.first_name} {data.notification.sender.last_name}</b> asked to be friends.</p>
          }
        </Col>
      </Row>
    </Card>
  )
}