import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Col } from 'react-bootstrap'
import { ChevronLeft } from 'react-bootstrap-icons'
import './styles.scss'

export default function PageTitle({ title, nav }) {
  const history = useHistory()

  return (
    <div className={'page-title'}>
      <Col
        lg={'6'}
        className={'mx-auto p-0'}
      >
        <h1 className={'page-title__text m-0 p-3 pt-4 pb-4'}>
          {nav ? 
            <Button
              className={'p-0 mr-2 mt-0'}
              variant={'link'}
              onClick={() => history.push(nav)}
            >
              <ChevronLeft size={28} />
            </Button>
          : null}
          {title}
        </h1>
      </Col>
    </div>
  )
}