import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { Button, Image } from 'react-bootstrap'
import User from '../../helpers/User'
import walkthroughWallet from '../../assets/walkthrough-wallet.png';
import walkthroughStrongbox from '../../assets/walkthrough-strongbox.png';
import walkthroughChart from '../../assets/walkthrough-chart.png';
import './styles.scss';
// import { store } from '../App';

function Walkthrough() {
  const [view, setView] = useState(0);
  const userData = new User().getData()
  const userId = userData.user.id
  const history = useHistory();

  function setTourViewed() {
    axios.put(`/api/walkthrough/${userId}`);
    history.push("/pari");
  }

  return (
    <div className="walkthrough">
      <header className="pt-2 w-100">
        <Button className="float-right end-tour" variant="link" onClick={() => setTourViewed()}>End Tour</Button>
      </header>
      <div className="walkthrough-image text-center pb-5">
        {
          view === 0 ?
            <Image src={walkthroughWallet} />
          : view === 1 ?
            <Image src={walkthroughStrongbox} />
          : <Image src={walkthroughChart} />
        }
      </div>
      <div className="walkthrough-nav text-center mt-5">
        <div className={view === 0 ? 'dot-long d-inline-block mr-2' : 'dot d-inline-block mr-2'} onClick={() => setView(0)}></div>
        <div className={view === 1 ? 'dot-long d-inline-block mr-2' : 'dot d-inline-block mr-2'} onClick={() => setView(1)}></div>
        <div className={view === 2 ? 'dot-long d-inline-block' : 'dot d-inline-block'} onClick={() => setView(2)}></div>
      </div>
      <div className="walkthrough-content">
        {
          view === 0 ?
            <div className="text-center">
              <h2 className="mt-5">Add Wallet to Pari</h2>
              <p className="mt-3">Add a debit card or bank account to send and receive funds!</p>
              <Button href="/wallet" variant="secondary" className="mt-3">
                GET STARTED
              </Button>
            </div>
          : view === 1 ?
            <div className="text-center">
              <h2 className="mt-5">Peer to Peer Lending</h2>
              <p className="mt-3">You can now operate as a lender and get friendly loans from your family and friends!</p>
              <Button href="/lend" variant="secondary" className="mt-3">
                GET STARTED
              </Button>
            </div>
          : <div className="text-center">
              <h2 className="mt-5">Point of Sale</h2>
              <p className="mt-3">You can now finance anything and everything you want as a buyer or seller!</p>
              <Button href="/buy-sell" variant="secondary" className="mt-3" disabled>
                COMING SOON
              </Button>
            </div>
        }
      </div>
    </div>
  )
}

export default Walkthrough;