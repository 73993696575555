import * as React from 'react'
import { useHistory } from 'react-router-dom'
import { usePlaidLink } from 'react-plaid-link'
import { Alert, Button, Col, Container, Form, Image, Row, Spinner } from 'react-bootstrap'
import { useMutationObservable } from '../../utils/hooks/useMutationObservable'
import PageTitle from '../../components/PageTitle'
import './styles.scss'
import * as dwollaStyles from './debit-payment.css'
import Swal from 'sweetalert2'
import axios from 'axios'

const DebitPayment = (props) => {
  const userId = props.location.pathname.split('/')[2]
  const cardContainerRef = React.useRef(null);
  const [cardToken, setCardToken] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(false)
  
  const onListMutation = React.useCallback(
    (mutationList) => {
      const idx = mutationList.findIndex(mutation => (mutation.target === cardContainerRef.current && mutation.type === 'childList'))
      if (idx > -1) {
        setTimeout(() => {
          cardContainerRef.current.style.display = 'block'
          setLoading(false)
        }, 1250)
      }
    }, []
  );

  useMutationObservable(cardContainerRef.current, onListMutation);

  const generateToken = async () => {
    const response = await fetch(`/api/create-card-funding-sources-token/${userId}`, {
      method: 'POST',
    });
    const data = await response.json();
    setCardToken(data.card_token);
  }

  const saveDebitCard = (id, fundingSource) => {
    const body = {
      id,
      nickname: document.getElementById('card-name-input').value,
      dwollaPath: fundingSource.href.substr(fundingSource.href.length - 36),
    }
    axios.post('/api/debit-cards', body)
  }

  React.useEffect(() => {
    window.dwolla.configure('sandbox')
    generateToken()
  }, [])

  React.useEffect(() => {
    setLoading(true)
    if (cardToken && cardToken.length > 0) {
      const options = {
        stylesheets: [dwollaStyles],
        styles: {
          success: {
            // valid input text
            color: "#1176BC",
            fontWeight: "bold",
          },
          error: {
            // invalid input text
            color: "#d9534f",
            fontWeight: "bold",
          },
          buttonText: "Add Card to Pari",
        },
      }
      cardContainerRef.current.style.display = 'none'
      window.dwolla.cards.start('cardContainer', cardToken, options)((err, res) => {
        if (res && !res.code) {
          saveDebitCard(userId, res._links['funding-source'])
        } else if (res && res.code) {
          setError(res.message)
        } else if (err) {
          setError(err._embedded.errors[0].message)
        }
        document.getElementById('instructions').style.display = 'none';
        document.getElementById('cardContainer').style.display = 'none';
        document.getElementById('result').style.display = 'block';
      })
    }
  }, [cardToken])

  return (
    <div>
      <PageTitle
        title={'Settings / Wallet / New Debit Card'}
        nav={'/wallet'}
      />
      <div className={'app-body app-body--page debit-payment'}>
        <Container className={'text-center pt-3 pb-3'}>
          <Col className={'mx-auto'} xs="12" md="6">
            <Row>
              {!loading && !error && (<div id="instructions">Please complete the below form to add a new debit card to your Pari Wallet.</div>)}
              {loading && (
                <div className="mx-auto mt-4">
                  <Spinner animation={'border'} role={'status'} variant={'primary'} />
                </div>
              )}
              <div>
                <div ref={cardContainerRef} id={'cardContainer'}></div>
                <div>{error}</div>
                <div id={'result'} style={{ display: 'none' }}>Card has been added!</div>
              </div>
            </Row>
          </Col>
        </Container>
      </div>
    </div>
  )
}

export default DebitPayment

