import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { Container, Col, Dropdown, Image, Spinner } from 'react-bootstrap'
import { Image as Cloudinary } from 'cloudinary-react'
import { store } from '../../helpers/Store'
import Content from '../../components/Content'
import PageTitle from '../../components/PageTitle'
import blankAvatar from '../../assets/blank-avatar.png'
import './styles.scss'

export default function FriendsDetails(props) {
  const globalState = useContext(store)
  const { dispatch } = globalState
  const { location } = props
  const id = location.pathname.replace('/friends/', '')
  const [ avatar, setAvatar ] = useState(null)
  const [ details, setDetails ] = useState(null)
  const [ loading, setLoading ] = useState(false)
  const [ valid, setValid ] = useState(null)

  useEffect(() => {
		const controller = new AbortController()
		
		const fetchData = async() => {
			setLoading(true)

      const userPromise = await axios.get(`/api/users/${id}`)
      const avatarPromise = await axios.get(`/api/avatar/${id}`)
      
      if(userPromise && userPromise.data.length){
        setDetails(userPromise.data[0])
        setAvatar(avatarPromise.data[0] || null)
        setValid(true)
      }else{
        setValid(false)
      }

      setLoading(false)
    }
    
    if(id)
      fetchData()

		return () => {
			controller.abort()
    }
  }, [ id ])

  const handleLend = () => {
    dispatch({type: 'transactionFriendId', value: id})
    dispatch({type: 'lend'})
  }

  const handleBorrow = () => {
    dispatch({type: 'transactionFriendId', value: id})
    dispatch({type: 'borrow'})
  }

  const handleBuySell = () => {
    dispatch({type: 'transactionFriendId', value: id})
    dispatch({type: 'buySell'})
  }

  return (
    <Content page={'friends'}>
      <PageTitle
        title={'Friends / Details'}
        nav={'/friends'}
      />
      <div className={'app-body app-body--page'}>
        <Container className={'pt-3 pb-3'}>
          <Col
            lg={'6'}
            className={'text-center mx-auto p-0'}
          >
            {loading ? (
              <div className={'w-100 mt-auto mb-auto text-center'}>
                <Spinner animation={'border'} role={'status'} variant={'primary'} />
              </div>
            ) : (
              <div className={'mt-auto mb-auto'}>
                {valid ? (
                  <div className={'mt-4'}>
                    <div className={'userpanel__avatar'}>
                      {avatar ?
                        <Cloudinary
                          cloudName={'dvfifodns'}
                          publicId={avatar.file_name}
                          width={200}
                          height={200}
                          crop={'thumb'}
                          gravity={'face'}
                        />           
                      :
                        <Image roundedCircle src={blankAvatar} />
                      }
                    </div>
                    <h4 className={'mt-4 text-secondary'}>{details.first_name + ' ' + details.last_name}</h4>
                    <h6 className={'mt-2 text-primary'}><em>Standard User</em></h6>
                    <h6 className={'mt-2 text-secondary'}>{details.email}</h6>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant={'primary'}
                        className={'mt-4 w-50'}
                        id={`dropdown-friends-${id}`}
                      >
                        New Transaction
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={handleLend}>Lend</Dropdown.Item>
                        <Dropdown.Item onClick={handleBorrow}>Borrow</Dropdown.Item>
                        {/* <Dropdown.Item onClick={handleBuySell}>Buy & Sell</Dropdown.Item> */}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                ) : (
                  <div className={'mt-4'}>
                    <h4 className={'text-secondary'}>User not found!</h4>
                  </div>
                )}
              </div>
            )}
          </Col>
        </Container>
      </div>
    </Content>
  )
}