import React, { useContext } from 'react'
import { Button, Container, Image } from 'react-bootstrap'
import { store } from '../../helpers/Store'
import './styles.scss'

const icons = {
  lend: require('../../assets/lend.png'),
  borrow: require('../../assets/borrow.png'),
  buySell: require('../../assets/buy-sell.png')
}

export default function OptionsMenu() {
  const globalState = useContext(store)
  const { dispatch } = globalState

  return (
    <Container className={'options-menu'}>
      <Button
        className={'options-menu__link'}
        onClick={() => dispatch({type: 'lend'})}
      >
        <div className={'options-menu__link__image'}>
          <Image src={icons.lend} />
        </div>
        <div>
          <small>Lend</small>
        </div>
      </Button>
      <Button
        className={'options-menu__link'}
        onClick={() => dispatch({type: 'borrow'})}
      >
        <div className={'options-menu__link__image'}>
          <Image src={icons.borrow} />
        </div>
        <div>
          <small>Borrow</small>
        </div>
      </Button>
      {/* <Button
        className={'options-menu__link'}
        onClick={() => dispatch({type: 'buySell'})}
      >
        <div className={'options-menu__link__image'}>
          <Image src={icons.buySell} />
        </div>
        <div>
          <small>Buy &amp; Sell</small>
        </div>
      </Button> */}
    </Container>
  )
}