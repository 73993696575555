import React from 'react'
import { Link } from 'react-router-dom'
import { Badge, Col, Image, Row } from 'react-bootstrap'
import { Image as Cloudinary } from 'cloudinary-react'
import { ChevronRight } from 'react-bootstrap-icons'
import blankAvatar from '../../assets/blank-avatar.png'
import './styles.scss'

export default function FriendsList({ friends }) {
  return (
    <div className={'friends-list'}>
      <p className={'friends-list__title pt-4 mb-0'}>
        <b>Your Friends</b>
        <Badge variant={'primary'} className={'ml-2'}>{friends.length}</Badge>
      </p>
      {friends.map(friend => (
        <Link key={friend._id} to={`/friends/${friend.user._id}`}>
          <Row className={'friends-list__item ml-0 mr-0'}>
            <Col className={'friends-list__item__avatar user-avatar-md'}>
              {'avatar' in friend.user && friend.user.avatar ?
                <Cloudinary
                  cloudName={'dvfifodns'}
                  publicId={friend.user.avatar.file_name}
                  width={50}
                  height={50}
                  crop={'thumb'}
                  gravity={'face'}
                />           
              :
                <Image roundedCircle src={blankAvatar} />
              }
            </Col>
            <Col className={'friends-list__item__name'}>
              <p><b>{`${friend.user.first_name} ${friend.user.last_name}`}</b></p>
            </Col>
            <Col className={'friends-list__item__arrow'}>
              <ChevronRight />
            </Col>
          </Row>
        </Link>
      ))}
    </div>
  )
}