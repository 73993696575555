import React from 'react'
import { Card, Col, Image, Row } from 'react-bootstrap'
import { Image as Cloudinary } from 'cloudinary-react'
import blankAvatar from '../../../assets/blank-avatar.png'
import moment from 'moment'

export default function TransactionAcceptCard(props) {
  const { userId, avatar, data, dismissed, dismissNotification } = props
  const isDown = data.notification.transfer.down
  const isNotificationSender = data.notification.sender._id === userId
  const isSender = data.notification.transfer.sender === userId
  const isInitiated = data.notification.transfer.initiatedBy === userId
  const notificationTime = moment(data.notification.initiated).format('hh:mm A')

  return (
    <Card className={'card'} onClick={() => !isNotificationSender && !dismissed && dismissNotification()}>
      <Row className={'pl-3 pr-3'}>
        <Col xs={'3'} className={'card__avatar user-avatar-md p-0'}>
          {avatar ?
            <Cloudinary
              cloudName={'dvfifodns'}
              publicId={avatar.file_name}
              width={40}
              height={40}
              crop={'thumb'}
              gravity={'face'}
            />           
          :
            <Image roundedCircle src={blankAvatar} />
          }
        </Col>
        <Col xs={'9'} className={`card__text ${!isNotificationSender && !dismissed ? 'card__text--bold card__text--cursor' : ''} p-0`}>
          <small>{notificationTime}</small> 
          <small className={'blue'}>{data.notification.transfer.notes}</small>
          {isDown && isInitiated && isSender && (
            <p><b>{data.notification.sender.first_name} {data.notification.sender.last_name}</b> accepted <b>your</b> offer to sell {data.notification.transfer.notes} for <b className={'blue'}><span className={'transaction-add'}>${data.notification.transfer.amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span></b>.</p>
          )}
          {isDown && !isInitiated && !isSender && (
            <p><b>You</b> accepted the offer to buy {data.notification.transfer.notes} from <b>{data.notification.receiver.first_name} {data.notification.receiver.last_name}</b> for <b className={'blue'}><span className={'transaction-add'}>${data.notification.transfer.amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span></b></p>
          )}
          {isDown && !isInitiated && isSender && (
            <p><b>You</b> accepted the request from <b>{data.notification.sender.first_name} {data.notification.sender.last_name}</b> to sell {data.notification.transfer.notes} for <b className={'blue'}><span className={'transaction-add'}>${data.notification.transfer.amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span></b>.</p>            
          )}
          {isDown && isInitiated && !isSender && (
            <p><b>{data.notification.receiver.first_name} {data.notification.receiver.last_name}</b> accepted <b>your</b> offer to purchase {data.notification.transfer.notes} for <b className={'blue'}><span className={'transaction-subtract'}>${data.notification.transfer.amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span></b>.</p>            
          )}
          {!isDown && isInitiated && isSender && (
            <p><b>{data.notification.receiver.first_name} {data.notification.receiver.last_name}</b> accepted <b>your</b> offer of <b className={'blue'}><span className={'transaction-subtract'}>${data.notification.transfer.amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span></b>.</p>
          )}
          {!isDown && !isInitiated && !isSender && (
            <p><b>You</b> accepted the offer from <b>{data.notification.sender.first_name} {data.notification.sender.last_name}</b> for <b className={'blue'}><span className={'transaction-add'}>${data.notification.transfer.amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span></b></p>            
          )}
          {!isDown && !isInitiated && isSender && (
            <p><b>You</b> accepted the request from <b>{data.notification.receiver.first_name} {data.notification.receiver.last_name}</b> for <b className={'blue'}><span className={'transaction-subtract'}>${data.notification.transfer.amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span></b>.</p>
          )}
          {!isDown && isInitiated && !isSender && (
            <p><b>{data.notification.sender.first_name} {data.notification.sender.last_name}</b> accepted <b>your</b> request for <b className={'blue'}><span className={'transaction-add'}>${data.notification.transfer.amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span></b></p>            
          )}
        </Col>
      </Row>
    </Card>
  )
}