import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Button, Col, Container, Image, Modal, Row, Spinner } from 'react-bootstrap'
import Card from '../Card'
import { Image as Cloudinary } from 'cloudinary-react'
import blankAvatar from '../../assets/blank-avatar.png'
import User from '../../helpers/User'
import moment from 'moment'

require('./styles.scss')

export default function Summary() {
  const userData = new User().getData()
  const id = userData.user.id
  const [ loading, setLoading ] = useState(false)
  const [ history, setHistory ] = useState([])
  const [ modalState, setModalState ] = useState(false)
  const nullModalValue = {
    date: undefined,
    idx: undefined,
  }
  const [ selectedModal, setSelectedModal ] = useState(nullModalValue)
  const [ notificationData, setNotificationData ] = useState(null)
  let index = 0

  const historySort = (history) => {
    return history.sort((n1, n2) => {
      const a = n1.initiated
      const b = n2.initiated

      return new Date(b).getTime() - new Date(a).getTime()
    })
  }
  
  const historyDates = (history) => {
    let dates = {}
    let order = []
    const sorted = historySort(history)

    sorted.forEach(n => {
      const date = new Date(n.initiated)
      const stringDate = date.toString().slice(4, 15)

      if(!(stringDate in dates)){
        dates[stringDate] = []
        order.push(stringDate)
      }

      dates[stringDate].push(n)
    })

    dates['order'] = order

    return dates
  }

  const handleClose = () => {
    setModalState(false)
  }

  useEffect(() => {
    if (modalState && selectedModal && selectedModal.date) {
      if (history[selectedModal.date][selectedModal.idx].type.includes('relationship')) {
        const isSender = id === history[selectedModal.date][selectedModal.idx].sender._id
        const accepted = history[selectedModal.date][selectedModal.idx].type.includes('relationshipAccepted')
        setNotificationData({
          timestamp: moment(history[selectedModal.date][selectedModal.idx].initiated).format('MM/DD/YYYY HH:mm a'),
          avatar: history[selectedModal.date][selectedModal.idx].sender.avatar,
          actingUser: 
            isSender
                ? 'You'
                : `${history[selectedModal.date][selectedModal.idx].sender.first_name} ${history[selectedModal.date][selectedModal.idx].sender.last_name}`,
          content:
            isSender && !accepted
              ? `You asked ${history[selectedModal.date][selectedModal.idx].receiver.first_name} ${history[selectedModal.date][selectedModal.idx].receiver.last_name} to be friends.`
              : isSender && accepted
                ? `You accepted the friend request from ${history[selectedModal.date][selectedModal.idx].receiver.first_name} ${history[selectedModal.date][selectedModal.idx].receiver.last_name}.`
                : !isSender && accepted
                  ? `${history[selectedModal.date][selectedModal.idx].receiver.first_name} ${history[selectedModal.date][selectedModal.idx].receiver.last_name} accepted your friend request.`
                  : `${history[selectedModal.date][selectedModal.idx].sender.first_name} ${history[selectedModal.date][selectedModal.idx].sender.last_name} asked you to be friends.`
        })
      } else if (history[selectedModal.date][selectedModal.idx].type.includes('transfer')) {
        const isSender = id === history[selectedModal.date][selectedModal.idx].transfer.sender
        const isInitiated = id === history[selectedModal.date][selectedModal.idx].transfer.initiatedBy
        const accepted = history[selectedModal.date][selectedModal.idx].type.includes('transferAccepted')
        const declined = history[selectedModal.date][selectedModal.idx].type.includes('transferDeclined')
        let avatar = null
        const actingUser = isInitiated && isSender
          ? (accepted || declined)
              ? `${history[selectedModal.date][selectedModal.idx].receiver.first_name} ${history[selectedModal.date][selectedModal.idx].receiver.last_name}`
              : 'You'
          : isInitiated
            ? (accepted || declined)
              ? `${history[selectedModal.date][selectedModal.idx].sender.first_name} ${history[selectedModal.date][selectedModal.idx].sender.last_name}`
              : 'You'
            : isSender
              ? (accepted || declined)
                ? 'You'
                : `${history[selectedModal.date][selectedModal.idx].receiver.first_name} ${history[selectedModal.date][selectedModal.idx].receiver.last_name}`
              : (accepted || declined)
                ? 'You'
                : `${history[selectedModal.date][selectedModal.idx].sender.first_name} ${history[selectedModal.date][selectedModal.idx].sender.last_name}`
        if (actingUser === 'You') {
          avatar = history[selectedModal.date][selectedModal.idx].sender._id === id ? history[selectedModal.date][selectedModal.idx].sender.avatar : history[selectedModal.date][selectedModal.idx].receiver.avatar
        }
        setNotificationData({
          timestamp: moment(history[selectedModal.date][selectedModal.idx].initiated).format('MM/DD/YYYY hh:mm A'),
          actingUser,
          avatar,          
          content:
            isInitiated && isSender
              ? (!accepted && !declined)
                ? `You offered ${history[selectedModal.date][selectedModal.idx].receiver.first_name} ${history[selectedModal.date][selectedModal.idx].receiver.last_name} $${history[selectedModal.date][selectedModal.idx].transfer.amount.toFixed(2)}.`
                : accepted
                  ? `${history[selectedModal.date][selectedModal.idx].receiver.first_name} ${history[selectedModal.date][selectedModal.idx].receiver.last_name} accepted your offer for $${history[selectedModal.date][selectedModal.idx].transfer.amount.toFixed(2)}.`
                  : `${history[selectedModal.date][selectedModal.idx].receiver.first_name} ${history[selectedModal.date][selectedModal.idx].receiver.last_name} declined your offer for $${history[selectedModal.date][selectedModal.idx].transfer.amount.toFixed(2)}.`
              : isInitiated && !isSender
                ? (!accepted && !declined)
                  ? `You requested $${history[selectedModal.date][selectedModal.idx].transfer.amount.toFixed(2)} from ${history[selectedModal.date][selectedModal.idx].receiver.first_name} ${history[selectedModal.date][selectedModal.idx].receiver.last_name}.`
                  : accepted
                    ? `${history[selectedModal.date][selectedModal.idx].receiver.first_name} ${history[selectedModal.date][selectedModal.idx].receiver.last_name} accepted your request for $${history[selectedModal.date][selectedModal.idx].transfer.amount.toFixed(2)}.`
                    : `${history[selectedModal.date][selectedModal.idx].receiver.first_name} ${history[selectedModal.date][selectedModal.idx].receiver.last_name} declined your request for $${history[selectedModal.date][selectedModal.idx].transfer.amount.toFixed(2)}.`
                : isSender
                  ? (!accepted && !declined)
                    ? `${history[selectedModal.date][selectedModal.idx].receiver.first_name} ${history[selectedModal.date][selectedModal.idx].receiver.last_name} requested $${history[selectedModal.date][selectedModal.idx].transfer.amount.toFixed(2)}.`
                    : accepted
                      ? `You accepted the request from ${history[selectedModal.date][selectedModal.idx].sender.first_name} ${history[selectedModal.date][selectedModal.idx].sender.last_name} for $${history[selectedModal.date][selectedModal.idx].transfer.amount.toFixed(2)}.`
                      : `You declined the request from ${history[selectedModal.date][selectedModal.idx].sender.first_name} ${history[selectedModal.date][selectedModal.idx].sender.last_name} for $${history[selectedModal.date][selectedModal.idx].transfer.amount.toFixed(2)}.` 
                  : (!accepted && !declined)
                    ? `${history[selectedModal.date][selectedModal.idx].sender.first_name} ${history[selectedModal.date][selectedModal.idx].sender.last_name} offered you $${history[selectedModal.date][selectedModal.idx].transfer.amount.toFixed(2)}.`
                    : accepted
                      ? `You accepted ${history[selectedModal.date][selectedModal.idx].sender.first_name} ${history[selectedModal.date][selectedModal.idx].sender.last_name}'s offer for $${history[selectedModal.date][selectedModal.idx].transfer.amount.toFixed(2)}.`
                      : `You declined ${history[selectedModal.date][selectedModal.idx].sender.first_name} ${history[selectedModal.date][selectedModal.idx].sender.last_name}'s offer for $${history[selectedModal.date][selectedModal.idx].transfer.amount.toFixed(2)}.` 
        })
      }
    } else {
      setSelectedModal(nullModalValue)
      setNotificationData(null)
    }
  }, [modalState])

  useEffect(() => {
    if (selectedModal && selectedModal.date) {
      setModalState(true)
    } else {
      setModalState(false)
    }
  }, [selectedModal])

  useEffect(() => {
		const controller = new AbortController()
		
		const fetchData = async() => {
			setLoading(true)

			const historyPromise = await axios.get(`/api/history/${id}`)  
      const notifications = historyDates(historyPromise.data)

      setHistory(notifications)
      setLoading(false)
    }
    
    if(id)
      fetchData()

		return () => {
			controller.abort()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ id ])

  return (
    <div className={'app-body app-body--page'}>
      <Container className={'pt-3 pb-3'}>
        <Col
          lg={'6'}
          className={'mx-auto p-0'}
        >   
          {loading ? 
            (
              <div className={'w-100 pt-5 pb-5 text-center'}>
                <div className={'mt-5'}>
                  <Spinner animation={'border'} role={'status'} variant={'primary'} />
                </div>
              </div>
            ) : (
              <div>
                {'order' in history && history['order'].length ?
                  (
                    history['order'].map((date) => {
                      return (
                        <div key={date}>
                          <p className={'dashboard__notifications__container__date mt-3 mb-3'}>{date}</p>
                          {history[date].map((n, idx) => {
                            let data = {}
                            index++
                            
                            if(n.type === 'relationship' || n.type === 'relationshipAccepted')
                              data = {
                                userId: id,
                                notification: n
                              }

                            if(n.type === 'payment')
                              data = {
                                userId: id,
                                notification: n
                              }

                            if(n.type === 'transfer' || n.type === 'transferAccepted' || n.type === 'transferDeclined')
                              data = {
                                userId: id,
                                notification: n
                              }
      
                            return (
                              <div
                                key={`${date}-item-${idx}`}
                                className={'cursor-pointer'}
                                onClick={() => {
                                  setSelectedModal({ date, idx })
                                }}
                              >
                                <Card                                  
                                  variant={n.type}
                                  index={index}
                                  data={data}
                                >
                                </Card>
                                <Modal
                                    show={(selectedModal && selectedModal.date && selectedModal.date === date && selectedModal.idx === idx) || false}
                                    onHide={handleClose}
                                >
                                  <Modal.Header>
                                    <Modal.Title>Transaction Detail</Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body>
                                    {notificationData ?
                                      <Container>
                                        <Row>
                                          <Col xs="3" className={'card__avatar user-avatar-md p-0'}>
                                            {notificationData.avatar ?
                                              <Cloudinary
                                                cloudName={'dvfifodns'}
                                                publicId={notificationData.avatar.file_name}
                                                width={40}
                                                height={40}
                                                crop={'thumb'}
                                                gravity={'face'}
                                              />           
                                            :
                                              <Image roundedCircle src={blankAvatar} />
                                            }
                                          </Col>
                                          <Col xs="9">
                                            <div><strong>Type:</strong> {data.notification.type.includes('relationship') ? 'Relationship' : 'Transfer'}</div>
                                            <div><strong>Time:</strong> {notificationData.timestamp}</div>
                                            <div><strong>Acting User:</strong> {notificationData.actingUser}</div>
                                            <div><strong>Details:</strong> {notificationData.content}</div>
                                          </Col>
                                        </Row>
                                      </Container>
                                    : null}
                                  </Modal.Body>
                                  <Modal.Footer>
                                    <Button variant="danger" onClick={handleClose}>
                                      Close
                                    </Button>
                                  </Modal.Footer>
                                </Modal>
                            </div>
                            )
                          })}
                        </div>
                      )
                    })
                  ) : <div>No transactions to display.</div>
                }
              </div>
            )
          }
        </Col>
      </Container>
    </div>
  )
}