import React from 'react'
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap'

export default function Step3(props) {
  const { loading, loan, payment, term, view, transactionView, paymentMethods, SelectedFriendName, changeStep, sendTransfer,loan_fee, enableloanfee } = props

  const [checkedTerms, setCheckedTerms] = React.useState(false)

  return (
    <div>
      <h2 className={'subtitle'}>Transaction Summary</h2>
      <dl className={'lendborrow__resume mt-4'}>
        <dt className={'pt-3 border-top'}>{transactionView === 'borrow' ? 'Amount You\'ll Receive' : 'Amount'}:</dt>
        <dd className={'pb-1'}>{loan['amount-mask']}</dd>
        <dt className={'pt-1'}>Pari Fees:</dt>
        <dd className={'pb-1'}>{loan['fees-mask']}</dd>
        <dt className={'pt-1'}>Total Loan Amount:</dt>
        <dd className={'pb-3 border-bottom'}><big>{loan['total-mask']}</big></dd>
        <dt className={'mt-3'}>Type:</dt>
        <dd>{ view === 0 ? 'By Term' : 'By Payment' }</dd>
        <dt className={'mt-3'}>{enableloanfee === 0 ? 'Interest Rate:' : 'Loan Fee:' }</dt>
        <dd>{enableloanfee === 0 ? `${loan['rate']} %` : loan_fee } </dd>
        <dt className={'mt-3'}>{view === 0 ? 'Term Length:' : 'Monthly Payment:' }</dt>
        <dd>{view === 0 ? `${loan['term']} Months` : loan['payment-mask']}</dd>
        <dt className={'mt-3'}>{view === 0 ? 'Monthly Payment:' : 'Term (Months):' }</dt>
        <dd>{view === 0 ? `$ ${payment.toFixed(2)}` : term}</dd>
        <dt className={'mt-3'}>{ transactionView === 'borrow' ? 'Deposit Account:' : 'Funding Source:' }</dt>
        <dd>{paymentMethods[loan['method']].bankName} (...{paymentMethods[loan['method']].identifier})</dd>
        <dt className={'mt-3'}>{ transactionView === 'borrow' ? 'Request from:' : 'Send to:' }</dt>
        <dd>
          <SelectedFriendName />
        </dd>
        {loan['notes'] ?
          <dt className={'mt-2'}>Notes:</dt>
          :
          null
        }
        {loan['notes'] ?
          <dd>{loan['notes']}</dd>
          :
          null
        }
      </dl>
      <Row>
        <Col xs={'12'}>
          <Form.Check
            checked={checkedTerms}
            type="checkbox"
            label="By checking this box and clicking the button below, I agree to be legally bound by the agreement if the transaction is accepted. This constitutes my electronic signature."
            onChange={e => setCheckedTerms(e.target.checked)}
          />
        </Col>
        <Col xs={'12'} className={'mt-4'}>
          <Button
            disabled={!checkedTerms}
            variant={'secondary'}
            className={'w-100'}
            size={'lg'}
            onClick={sendTransfer}
          >
            { loading ? <Spinner animation={'border'} role={'status'} variant={'light'} /> : transactionView === 'borrow' ? 'Request Loan' : 'Offer Loan' }
          </Button>
        </Col>
        <Col xs={'12'} className={'mt-4'}>
          <Button
            onClick={e => changeStep(e, 2)}
            variant={'light'}
            className={'w-100'}
            size={'lg'}
          >
            Previous
          </Button>
        </Col>
      </Row>
    </div>
  )
}