import React from 'react'
import { Button, Col, Container, Form, NavLink, OverlayTrigger, Popover, Spinner } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { InfoCircleFill } from 'react-bootstrap-icons'

export default function TransactionModalPanel(props) {
  const { acceptOffer, acceptPurchase, accounts, data, declineOffer, handleChange, loading, transferAccount, withdrawOffer } = props
  const [checkedTerms, setCheckedTerms] = React.useState(false)
  const history = useHistory()

  const navigateToWallet = () => {
    history.push('/wallet')
  }

  return (
    <Container className={'transactionpanel'}>
      <Col sm={'6'} className={'transactionpanel__flex mx-auto pt-3 pb-5'}>
        <div className={'mt-4'}>
          <h1 className={'title'}>Review Transaction</h1>
        </div>
        {
          data.notification.transfer.down && data.handleModal ? (
            <div className={'transaction mt-4 mb-4'}>
              {
                data.notification.transfer.sender === data.userId ? (
                  <div>
                    {data.notification.transfer.initiatedBy === data.userId ? <h6>You offered to sell {data.notification.receiver.first_name + ' ' + data.notification.receiver.last_name} {data.notification.transfer.notes} for ${data.notification.transfer.amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} on {Date(data.notification.transfer.initiated).toString().slice(4, 15)}.</h6> : (<h6>{data.notification.receiver.first_name + ' ' + data.notification.receiver.last_name} offered to purchase {data.notification.transfer.notes} from you for ${data.notification.transfer.amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} on {Date(data.notification.transfer.initiated).toString().slice(4, 15)}.</h6>)}
                    <h2 className={'subtitle mt-4'}>Proposed Terms:</h2>
                    <ul>
                    <li className={'border-top border-bottom'}>Total of Payments: <strong><big>${(data.monthlyPayment(data.notification.transfer.amount - data.notification.transfer.down, data.notification.transfer.rate / 100 / 12, data.notification.transfer.term) * data.notification.transfer.term + data.notification.transfer.down).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</big></strong></li>
                      <li>Number of Payments: <strong>{data.notification.transfer.term}</strong></li>
                      <li>Interest Rate: <strong>{data.notification.transfer.rate}%</strong></li>
                      <li>Monthly Payment: <strong>${data.monthlyPayment(data.notification.transfer.amount - data.notification.transfer.down, data.notification.transfer.rate / 100 / 12, data.notification.transfer.term).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</strong></li>
                    </ul>
                    { data.notification.transfer.initiatedBy !== data.userId && <h2 className={'subtitle mt-4 mb-3'}>Account to Receive Payments:</h2> }
                    {
                      accounts.length < 1 ? (
                        <h6 className={'text-danger'}>You need to <NavLink className={'p-0 text-light'} onClick={navigateToWallet}>add a payment method</NavLink> to receive payments.</h6>
                      ) : data.notification.transfer.initiatedBy !== data.userId && (
                        <Form.Control
                          as={'select'}
                          className={'pt-0 white'}
                          name={'method'}
                          placeholder={'Select Account'}
                          onChange={e => handleChange(e)}
                        >
                          <option value={-1}>--</option>
                          {
                            accounts.length > 0 && accounts.map((pm, i) => {
                              return <option key={`pm-${i}`} value={i}>{pm.bankName ? `${pm.bankName} ` : ''} {pm.nickname} {pm.identifier ? `...(${pm.identifier})` : ''}</option>
                            })
                          }
                        </Form.Control>
                      )
                    }
                  </div>
                ) : (
                  <div>
                    {data.notification.transfer.initiatedBy === data.userId ? (<h6>You offered to purchase {data.notification.transfer.notes} from {data.notification.receiver.first_name + ' ' + data.notification.receiver.last_name} for ${data.notification.transfer.amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} on {Date(data.notification.transfer.initiated).toString().slice(4, 15)}.</h6>) : (<h6>{data.notification.sender.first_name + ' ' + data.notification.sender.last_name} offered to sell you {data.notification.transfer.notes} for ${data.notification.transfer.amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} on {Date(data.notification.transfer.initiated).toString().slice(4, 15)}.</h6>)}
                    <h2 className={'subtitle mt-4'}>Proposed Terms:</h2>
                    <ul>
                      <li className={'border-top border-bottom'}>Total of Payments: <strong><big>${(data.notification.transfer.down + data.monthlyPayment(data.notification.transfer.amount - data.notification.transfer.down, data.notification.transfer.rate / 100 / 12, data.notification.transfer.term) * data.notification.transfer.term).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</big></strong></li>
                      <li>Number of Payments: <strong>{data.notification.transfer.term}</strong></li>
                      <li>Interest Rate: <strong>{data.notification.transfer.rate}%</strong></li>
                      <li>
                        <span>Monthly Payment: <strong>${data.monthlyPayment(data.notification.transfer.amount - data.notification.transfer.down, data.notification.transfer.rate / 100 / 12, data.notification.transfer.term).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</strong> <em>plus fees</em></span>
                        <OverlayTrigger
                          trigger={'focus'}
                          placement={'bottom'}
                          overlay={
                            <Popover id={'popover-fees'} style={{ zIndex: '1040' }}>
                              <Popover.Content>
                                <em>Pari charges a 1.25% transaction fee on top of the first $1,000.00 for each payment you make. For example, if your monthly payment is $200.00, the total amount you will be charged is $202.50. The payments listed on your dashboard will reflect these fees.</em>
                              </Popover.Content>
                            </Popover>
                          }
                        >
                          <Button
                            className={'p-0'}
                            size={'sm'}
                            variant={'link'}
                          >
                            <small className={'text-primary ml-1'}>
                              <InfoCircleFill size={20} />
                            </small>
                          </Button>
                        </OverlayTrigger>
                      </li>
                      <li>
                        <span>Down Payment: <strong>${(data.notification.transfer.down).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</strong> <em>plus fees</em></span>
                        <OverlayTrigger
                          trigger={'focus'}
                          placement={'bottom'}
                          overlay={
                            <Popover id={'popover-fees'}>
                              <Popover.Content>
                                <em>Pari charges a 1.25% transaction fee on top of the first $1,000.00 for each payment you make. For example, if your monthly payment is $200.00, the total amount you will be charged is $202.50. The payments listed on your dashboard will reflect these fees.</em>
                              </Popover.Content>
                            </Popover>
                          }
                        >
                          <Button
                            className={'p-0'}
                            size={'sm'}
                            variant={'link'}
                            >
                              <small className={'text-primary ml-1'}>
                                <InfoCircleFill size={20} />
                              </small>
                            </Button>
                        </OverlayTrigger>
                      </li>
                      {data.notification.transfer.initiatedBy === data.userId && data.notification.transfer.sender === data.userId && <li>Payment Account: {accounts.length > 0 && accounts.find(a => a._id === data.notification.transfer.method && a.nickname)} X-{accounts.length > 0 && accounts.find(a => a._id === data.notification.transfer.method && a.identifier)}</li> }
                      { data.notification.transfer.initiatedBy !== data.userId && data.notification.transfer.receiver !== data.userId && <h2 className={'subtitle mt-4 mb-3'}>Account to Recieve Payments:</h2> }
                      {
                        accounts.length < 1 ? (
                          <h6 className={'text-danger'}>You need to <NavLink className={'p-0 text-light'} onClick={navigateToWallet}>add a payment method</NavLink> to receive payments.</h6>
                        ) : data.notification.transfer.initiatedBy !== data.userId && (
                          <Form.Control
                            as={'select'}
                            className={'pt-0 white'}
                            name={'method'}
                            placeholder={'Select Account'}
                            onChange={e => handleChange(e)}
                          >
                            <option value={-1}>--</option>
                            {
                              accounts.length > 0 && accounts.map((pm, i) => {
                                return <option key={`pm-${i}`} value={i}>{pm.bankName ? `${pm.bankName} ` : ''} {pm.nickname} {pm.identifier ? `...(${pm.identifier})` : ''}</option>
                              })
                            }
                          </Form.Control>
                        )
                      }
                    </ul>
                  </div>
                )
              }
            </div>
          ) : data.handleModal && (
            <div className={'transaction mt-4 mb-4'}>
              {
                data.notification.transfer.sender === data.userId ? (
                  <div>
                    {data.notification.transfer.initiatedBy === data.userId ? (<h6>You offered {data.notification.receiver.first_name + ' ' + data.notification.receiver.last_name} ${data.notification.transfer.amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} on {Date(data.notification.transfer.initiated).toString().slice(4, 15)}.</h6>) : (<h6>{data.notification.receiver.first_name + ' ' + data.notification.receiver.last_name} requested ${data.notification.transfer.amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} on {Date(data.notification.transfer.initiated).toString().slice(4, 15)}.</h6>)}
                    <h2 className={'subtitle mt-4'}>{data.notification.transfer.initiatedBy === data.notification.transfer.receiver ? 'Request' : 'Offer'} Terms:</h2>
                    <ul>
                    <li className={'border-top border-bottom'}>Total of Payments: <strong><big>${(data.monthlyPayment(data.notification.transfer.amount, data.notification.transfer.rate / 100 / 12, data.notification.transfer.term) * data.notification.transfer.term).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</big></strong></li>
                      <li>Number of Payments: <strong>{data.notification.transfer.term}</strong></li>
                      <li>Interest Rate: <strong>{data.notification.transfer.rate}%</strong></li>
                      <li>Monthly Payment: <strong>${data.monthlyPayment(data.notification.transfer.amount, data.notification.transfer.rate / 100 / 12, data.notification.transfer.term).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</strong></li>
                    </ul>
                    { data.notification.transfer.initiatedBy !== data.userId && <h2 className={'subtitle mt-4 mb-3'}>Account to Send From:</h2> }
                    {
                      accounts.length < 1 ? (
                        <h6 className={'text-danger'}>You need to <NavLink className={'p-0 text-light'} onClick={navigateToWallet}>add a payment method</NavLink> to receive this transfer.</h6>
                      ) : data.notification.transfer.initiatedBy !== data.userId && (
                        <Form.Control
                          as={'select'}
                          className={'pt-0 white'}
                          name={'method'}
                          placeholder={'Select Account'}
                          onChange={e => handleChange(e)}
                        >
                          <option value={-1}>--</option>
                          {
                            accounts.length > 0 && accounts.map((pm, i) => {
                              return <option key={`pm-${i}`} value={i}>{pm.bankName ? `${pm.bankName} ` : ''} {pm.nickname} {pm.identifier ? `...(${pm.identifier})` : ''}</option>
                            })
                          }
                      </Form.Control>
                    )
                  }
                  </div>
                ) : (
                  <div>
                    <h6>You were offered ${data.notification.transfer.amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} by {data.notification.sender.first_name + ' ' + data.notification.sender.last_name} on {Date(data.notification.transfer.initiated).toString().slice(4, 15)}.</h6>
                    <h2 className={'subtitle mt-4'}>Offer Terms:</h2>
                    <ul>
                    <li className={'border-top border-bottom'}>Total of Payments: <strong><big>${(data.monthlyPayment(data.notification.transfer.amount, data.notification.transfer.rate / 100 / 12, data.notification.transfer.term) * data.notification.transfer.term).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</big></strong></li>
                      <li>Number of Payments: <strong>{data.notification.transfer.term}</strong></li>
                      <li>Interest Rate: <strong>{data.notification.transfer.rate}%</strong></li>
                      <li>
                        Monthly Payment: <strong>${data.monthlyPayment(data.notification.transfer.amount, data.notification.transfer.rate / 100 / 12, data.notification.transfer.term).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</strong> <em>plus fees</em>
                        <OverlayTrigger
                          trigger={'focus'}
                          placement={'bottom'}
                          overlay={
                            <Popover id={'popover-fees'}>
                              <Popover.Content>
                                <em>Pari charges a 1.25% transaction fee on top of the first $1,000.00 for each payment you make. For example, if your monthly payment is $200.00, the total amount you will be charged is $202.50. The payments listed on your dashboard will reflect these fees.</em>
                              </Popover.Content>
                            </Popover>
                          }
                        >
                          <Button
                            className={'p-0'}
                            size={'sm'}
                            variant={'link'}
                          >
                            <small className={'text-primary ml-1'}>
                              <InfoCircleFill size={20} />
                            </small>
                          </Button>
                        </OverlayTrigger>
                      </li>
                      <li>Pari Fee: <strong className={'text-danger'}>${(data.notification.transfer.amount * 0.0125).toFixed(2) > 12.5 ? (12.5).toFixed(2) : (data.notification.transfer.amount * 0.0125).toFixed(2)}</strong></li>
                      <li>You'll Receive: <strong className={'text-success'}>${(data.notification.transfer.amount - ((data.notification.transfer.amount * 0.0125).toFixed(2) > 12.5 ? (12.5).toFixed(2) : (data.notification.transfer.amount * 0.0125).toFixed(2))).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</strong></li>
                    </ul>
                    <h2 className={'subtitle mt-4 mb-3'}>Account to Deposit to:</h2>
                    {
                      accounts.length < 1 ? (
                        <h6 className={'text-danger'}>You need to <NavLink className={'p-0 text-light'} onClick={navigateToWallet}>add a payment method</NavLink> to receive this transfer.</h6>
                      ) : (
                        <Form.Control
                          as={'select'}
                          className={'pt-0 white'}
                          name={'method'}
                          placeholder={'Select Account'}
                          onChange={e => handleChange(e)}
                        >
                          <option value={-1}>--</option>
                          {
                            accounts.length > 0 && accounts.map((pm, i) => {
                              return <option key={`pm-${i}`} value={i}>{pm.bankName ? `${pm.bankName} ` : ''} {pm.nickname} {pm.identifier ? `...(${pm.identifier})` : ''}</option>
                            })
                          }
                      </Form.Control>
                    )
                  }
                  </div>
                )
              }
            </div>
          )
        }
        <div>
          {data.notification.transfer.initiatedBy !== data.userId ? (
            <Col xs={'12'} className={'mb-4'}>
              <Form.Check
                checked={checkedTerms}
                type="checkbox"
                label="By accepting this transaction, I agree to be legally bound by the agreement. This constitutes my electronic signature."
                onChange={e => setCheckedTerms(e.target.checked)}
              />
            </Col>
          ) : null}
          {loading ? (
            <Spinner
              variant="light"
              as={'span'}
              animation={'border'}
            /> 
          ) : (
            data.notification.transfer.initiatedBy === data.userId ? 
              data.notification.transfer.sender === data.userId ? (
                <Button 
                  variant={'danger'} 
                  disabled={loading} 
                  onClick={() => withdrawOffer(data.notification.transfer)}
                >
                  Withdraw Offer
                </Button>
              ) : (
                <Button 
                  variant={'danger'}
                  disabled={loading}
                  onClick={() => withdrawOffer(data.notification.transfer)}
                >
                  Withdraw Request
                </Button>
            ) :data.notification.transfer.sender === data.userId ? (
              <div>
                <Button
                  variant={'danger'}
                  disabled={loading}
                  onClick={() => declineOffer(data.notification.transfer)}
                >
                  Decline Request
                </Button>
                <Button
                  variant={'success'}
                  className={'ml-2'}
                  disabled={accounts.length === 0 || transferAccount === -1 || transferAccount === '-1' || !checkedTerms || loading}
                  onClick={() => data.notification.transfer.down ? acceptPurchase(data.notification.transfer) : acceptOffer(data.notification.transfer)}
                >
                  Accept Request
                </Button>
            </div>
              ) : (
              <div>
                <Button
                  variant={'danger'}
                  disabled={loading}
                  onClick={() => declineOffer(data.notification.transfer)}
                >
                  Decline Offer
                </Button>
                <Button
                  variant={'success'}
                  className={'ml-2'}
                  disabled={accounts.length === 0 || transferAccount === -1 || transferAccount === '-1' || !checkedTerms || loading}
                  onClick={() => data.notification.transfer.down ? acceptPurchase(data.notification.transfer) : acceptOffer(data.notification.transfer)}
                >
                  Accept Offer
                </Button>
              </div>
            )
          )}
        </div>
      </Col>
    </Container>
  )
}