import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import Swal from 'sweetalert2'
import { Badge, Button, Col, Form, Image, Row } from 'react-bootstrap'
import { Image as Cloudinary } from 'cloudinary-react'
import { PlusCircle } from 'react-bootstrap-icons'
import blankAvatar from '../../assets/blank-avatar.png'

export default function FriendsSearch({ userId }) {
  const history = useHistory()
  const [ searchResults, setSearchResults ] = useState([])

  const handleChange = async(e) => {
    const value = e.target.value

    if(value && value.length > 3) {
      const res = await axios.get(`/api/users/search/${value}`)

      const test = res.data.filter(d => d._id !== userId)
      setSearchResults(test)
    }
  }

  const addFriend = async(id) => {
    try{
      await axios.post(`/api/relationships/${userId}/${id}`)

      await Swal.fire({
        icon: 'success',
        title: 'Success!',
        html: 'Friend request sent!'
      })
  
      history.go(0)
    }catch(err){
      console.log(err.response)
      await Swal.fire({
        icon: 'error',
        title: 'Error!',
        html: 'response' in err ? err.response.data : err
      })
    }
  }

  return (
    <div className={'friends-list'}>
      <p className={'friends-list__title pt-4 mb-0'}>
        <b>Search by Name</b>
      </p>
      <Form.Control
        className={'search-box mt-3'}
        type={'text'}
        onChange={handleChange}
      />
      {searchResults && searchResults.length ? (
        <div>
          <p className={'friends-list__title pt-4 mb-0'}>
            <b>Results</b>
            <Badge variant={'primary'} className={'ml-2'}>{searchResults.length}</Badge>
          </p>
          {searchResults.map(user => (
            <Row key={user._id} className={'friends-list__item ml-0 mr-0'}>
              <Col className={'friends-list__item__avatar user-avatar-md'}>
              {'avatar' in user && user.avatar && user.avatar.length ?
                <Cloudinary
                  cloudName={'dvfifodns'}
                  publicId={user.avatar[0].file_name}
                  width={50}
                  height={50}
                  crop={'thumb'}
                  gravity={'face'}
                />           
              :
                <Image roundedCircle src={blankAvatar} />
              }
            </Col>
              <Col className={'friends-list__item__name'}>
                <p><b>{user.first_name} {user.last_name}</b></p>
              </Col>
              <Col className={'friends-list__item__arrow'}>
                <Button
                  size={'lg'}
                  variant={'link'}
                  type={'link'}
                  onClick={() => addFriend(user._id)}
                >
                  <PlusCircle />
                </Button>
              </Col>
            </Row>
          ))}
        </div>
      ) : null}
    </div>
  )
}