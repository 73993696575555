import * as React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Container, Col, Image, Form, Button, Spinner } from 'react-bootstrap'
import Swal from 'sweetalert2'
import axios from 'axios'
import moment from 'moment'
import logo from '../../assets/logo.png'

import './index.scss'

const ManualPayment = ({ location }) => {
  const history = useHistory();
  const pathArr = location.pathname.split('/')
  const tokenId = pathArr[pathArr.length - 1]
  const [loading, setLoading] = React.useState(false)
  const [payment, setPayment] = React.useState({})
  const [sender, setSender] = React.useState('')
  const [userInfo, setUserInfo] = React.useState({})
  const [receiver, setReceiver] = React.useState('')
  const [receiverName, setReceiverName] = React.useState('')
  const [amountDue, setAmountDue] = React.useState('')
  const [fundingOptions, setFundingOptions] = React.useState({})
  const [selectedMethod, setSelectedMethod] = React.useState('');

  const createAndProcessPayment = async() => {
    setLoading(true)

    const transfer = {
      originalPayment: tokenId,
      amount: amountDue,
      date: moment(),
      sendMethod: fundingOptions[Number(selectedMethod)]._id,
      sender: payment.sender,
      receiveMethod: payment.receiveMethod,
      receiver: payment.receiver,
      transferId: payment.transferId,
      status: 0,
    }

    try{
      await axios.post('/api/manualTransfers', transfer)
      
      await Swal.fire({
        icon: 'success',
        title: 'Success!'
      })
      history.go(0)
      setLoading(false)
    }catch(err){
      await Swal.fire({
        icon: 'error',
        title: 'Error!',
        html: err.response.data
      })

      setLoading(false)
    }    
  }

  React.useEffect(() => {
		const fetchData = async() => {
			setLoading(true)

      const paymentPromise = await axios.get(`/api/payments/${tokenId}`)
      setPayment(paymentPromise.data)
      setAmountDue((Number(paymentPromise.data.payment) + 10).toFixed(2) )
      setSender(paymentPromise.data.sender)
      setReceiver(paymentPromise.data.receiver)
      setLoading(false)
    }
    
    if(tokenId && tokenId.length > 0) {
      fetchData()
    }
  }, [])

  React.useEffect(() => {
    const fetchData = async() => {
			setLoading(true)

      const userPromise = await axios.get(`/api/users/${receiver}`)
      setReceiverName(`${userPromise.data[0].first_name} ${userPromise.data[0].last_name}`)
      setLoading(false)
    }
    
    if(receiver && receiver.length > 0) {
      fetchData()
    }
  }, [receiver])

  React.useEffect(() => {
    const fetchData = async() => {
			setLoading(true)

      const userPromise = await axios.get(`/api/users/${sender}`)
      const fundingPromise = await axios.get(`/api/funding-source/${sender}`)
      setUserInfo(userPromise.data[0])
      setFundingOptions(fundingPromise.data)
      setLoading(false)
    }
    
    if(sender && sender.length > 0) {
      fetchData()
    }
  }, [sender])

  React.useEffect(() => {
    if (!loading && payment && payment.status && payment.status !== 4) {
      history.push('/login')
    }
  })

  return !loading ? (
    <div className={'manual-payment'}>
    <Container fluid>
      <Col sm={'6'} className={'mx-auto h-100'} style={{ minHeight: '100vh' }}>
        <div className={'pt-5 pb-3'}>
          <Image
            src={logo}
            className={'main-logo'}
          />
        </div>
        <Form
          onSubmit={(e) => {
            e.preventDefault()
            createAndProcessPayment()
          }}
        >
          <h1>Hi, { userInfo.first_name }!</h1>
          <h2 className={'subtitle'}>Please Make a Payment</h2>
          <div className={'my-4'}>
            Unfortunately, it looks like we weren't able to process your payment to { receiverName } that was due on {moment(payment.date).format('MMMM Do, YYYY')}. Please select an account below from which to pay your balance due of <strong>${amountDue}</strong> today. This amount includes your late/returned item fee.<br /><br /> If you need to use a different account, you will need to add it manually by <Link target="_blank" to="/login">logging in</Link> to your account. You can refresh or return to this page once your new account is active and finish the payment process.<br /><br/>By clicking the button below, you are authorizing Pari to process a payment from the selected account. This transaction cannot be canceled.
          </div>
          <div className={'mt-4'}>
            <Form.Group className={'mt-4'} controlId={'method'}>
              <Form.Label>Funding Source for Payment:</Form.Label>
              <Form.Control
                required
                as={'select'}
                className={'white'}
                name={'method'}
                placeholder={'Select Account'}
                value={selectedMethod}
                onChange={e => setSelectedMethod(e.target.value)}
              >
                <option value={''}>--</option>
                {
                  fundingOptions.length > 0 && fundingOptions.map((pm, i) => {
                    return pm.verified && <option key={`pm-${i}`} value={i}>{pm.bankName ? `${pm.bankName} ` : ''} {pm.nickname} {pm.identifier ? `...(${pm.identifier})` : ''}</option>
                  })
                }
              </Form.Control>
            </Form.Group>
            <Button
              className={'w-100'}
              size={'lg'}
              type={'submit'}
              disabled={loading || selectedMethod === ''}
            >
              AUTHORIZE AND PROCESS PAYMENT FOR ${ amountDue }
            </Button>
          </div>
        </Form>
      </Col>
    </Container>
  </div>
  ) : (
    <div className={'manual-payment'}>
      <Container fluid>
        <Col sm={'6'} className={'mx-auto'}>
          <div className={'pt-5 pb-5'}>
            <Image
              src={logo}
              className={'main-logo'}
            />
          </div>
          <Spinner
            variant="light"
            as={'span'}
            animation={'border'}
          />
        </Col>
      </Container>
    </div>
  )
}

export default ManualPayment
