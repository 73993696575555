import * as React from 'react'
import { useHistory } from 'react-router-dom'
import { usePlaidLink } from 'react-plaid-link'
import { Alert, Button, Col, Container, Form, Image, Row, Spinner } from 'react-bootstrap'
import PageTitle from '../../components/PageTitle'
import './styles.scss'
import Swal from 'sweetalert2'
import axios from 'axios'

const pariLogo = require('../../assets/logo.png')
const plaidLogo = require('../../assets/plaid-logo.png')

const Link = (props) => {
  const history = useHistory();
  const onSuccess = React.useCallback((public_token, metadata) => {
    fetch(`/api/set-access-token/${props.userId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ public_token, accounts: metadata.accounts, institution: metadata.institution.name }),
    })
    .then((response) => {
      Swal.fire({
        icon: 'success',
        title: 'Success!',
        html: 'Bank account(s) successfully added!!'
      })
      .finally(() => {
        history.push('/wallet')
        history.go(0)
      })
    })
  }, []);
  const config = {
    token: props.linkToken,
    onSuccess,
  };
  const { open, ready } = usePlaidLink(config);
  return (
    <button onClick={() => open()} disabled={!ready}>
      Link account
    </button>
  );
};

const PlaidPayment = (props) => {
  const userId = props.location.pathname.split('/')[2]
  const [linkToken, setLinkToken] = React.useState(null);

  const generateToken = async () => {
    const response = await fetch(`/api/create-link-token/${userId}`, {
      method: 'POST',
    });
    const data = await response.json();
    setLinkToken(data.link_token);
  }

  React.useEffect(() => {
    generateToken();
  }, [])

  return (
    <div>
      <PageTitle
        title={'Settings / Wallet / New Bank Account'}
        nav={'/wallet'}
      />
      <div className={'app-body app-body--page'}>
        <Container className={'text-center pt-3 pb-3'}>
          <Col className={'mx-auto'} xs="12" md="6">
            <Row className={'logo-row'}>
              <Col xs="5">
                <img className={'payment-method-logo'} src={pariLogo} />
              </Col>
              <Col xs="2" className={'p-0'}>
                <h2 className={'arrow-h2'}>&#8592;</h2>
                <h2 className={'arrow-h2'}>&#8594;</h2>
              </Col>
              <Col xs="5">
                <img className={'payment-method-logo mx-auto my-3'} src={plaidLogo} />
              </Col>
            </Row>
            <div className={'mb-3'}>Pari uses Plaid to instantly and securely link your bank account to your Pari Wallet. Click the button below to get started!</div>
            <div className={'plaid-link-btn'}>{linkToken ? <Link userId={userId} linkToken={linkToken} /> : null}</div>
          </Col>
        </Container>
      </div>
    </div>
  )
}

export default PlaidPayment

