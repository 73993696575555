export default class User {
	setData = (state) => {
		window.localStorage.setItem('user-state', JSON.stringify(state))
	}

	removeData = () => {
		window.localStorage.removeItem('user-state')
	}

	getData = () => {
		const data = localStorage.getItem('user-state')
		return data && data !== undefined && data !== null && data !== 'undefined' ? JSON.parse(data) : null
	}
}

export const hasUser = () => {
	const user = new User()
	const data = user.getData()
	return data && 'loggedIn' in data && data.loggedIn
}