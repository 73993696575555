import React from 'react'
import { Drawer } from 'baseui/drawer'
import TransactionModalPanel from './TransactionModalPanel'

export default function TransactionModal(props) {
  const { data, index } = props

  return (
    <Drawer
      anchor={'bottom'}
      isOpen={data.showModal && data.visibleModal === index}
      onClose={data.handleHide && data.handleHide}
      showBackdrop
      overrides={{
        Root: {
          style: {
            zIndex: 2
          }
        },
        DrawerContainer: {
          style: {
            backgroundColor: '#64b1ff !important',
            height: '100vh'
          }
        },
        DrawerBody: {
          style: {
            margin: 0
          }
        },
        Close: {
          style: {
            fill: 'white',
            top: '0',
            left: '0',
            height: '50px',
            width: '50px',
            position: 'fixed'
          }
        }
      }}
    >
      <TransactionModalPanel
        {...props}
      />
    </Drawer>
  )
}