import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import Swal from 'sweetalert2'
import FriendCard from './cards/Friend'
import FriendModal from './cards/FriendModal'
import FriendAcceptCard from './cards/FriendAccept'
import PaymentCard from './cards/Payment'
import TransactionCard from './cards/Transaction'
import TransactionModal from './cards/TransactionModal'
import TransactionAcceptCard from './cards/TransactionAccept'
import TransactionDeclineCard from './cards/TransactionDecline'
import User from '../../helpers/User'
import './styles.scss'

export default function Card({ variant, index, data }) {
  const userData = new User().getData()
  const userId = userData.user.id
  const history = useHistory()
  const accounts = data.accounts
  const [ dismissed, setDismissed ] = useState(data.notification.dismissed)
  const [ loading, setLoading ] = useState(false)
  const [ transferAccount, setTransferAccount] = useState(-1)

  const handleChange = e => {
    const { value } = e.target
    setTransferAccount(value)
  }

  const acceptOffer = async(transaction) => {
    setLoading(true)

    let amount = 0

    if (transaction.amount <= 1000) {
      amount = ((transaction.amount - (0.0125 * transaction.amount)) * 1.005).toFixed(2)
    } else {
      amount = (transaction.amount - 7.5).toFixed(2)
    }

    const acceptBody = {
      _id: transaction._id,
      amount,
      source: transaction.initiatedBy !== userId && transaction.receiver === userId ? accounts[transferAccount].dwollaPath : transaction.method,
      destination: transaction.initiatedBy !== userId && transaction.receiver === userId ? transaction.method : accounts[transferAccount].dwollaPath
    }

    await axios.put(`/api/transfers/${transaction._id}`, acceptBody)
    await Swal.fire({
      icon: 'success',
      title: 'Success!'
    })
    await dismissNotification()

    setLoading(false)
    history.go(0)
  }

  const acceptPurchase = async(transaction) => {
    setLoading(true)

    let down = 0

    if (transaction.down <= 1000) {
      down = (1.0125 * transaction.down).toFixed(2)
    } else {
      down = (transaction.down + 12.5).toFixed(2)
    }

    const acceptBody = {
      _id: transaction._id,
      amount: down,
      source: transaction.initiatedBy !== userId && transaction.receiver === userId ? accounts[transferAccount]._id : transaction.method,
      destination: transaction.initiatedBy !== userId && transaction.receiver === userId ? transaction.method : accounts[transferAccount]._id
    }

    await axios.put(`/api/transfers/${transaction._id}`, acceptBody)
    await dismissNotification()

    setLoading(false)
    history.go(0)
  }

  const declineOffer = async(transaction) => {
    setLoading(true)

    await axios.put(`/api/transfers/${transaction._id}/decline`)
    await dismissNotification()

    setLoading(false)
    history.go(0)
  }

  const withdrawOffer = async(transaction) => {
    setLoading(true)

    await axios.put(`/api/transfers/${transaction._id}/withdraw`)
    await dismissNotification()
    
    setLoading(false)
    history.go(0)
  }

  const dismissNotification = async() => {
    await axios.put(`/api/notifications/${data.notification._id}/dismiss`)
    setDismissed(true)
  }

  switch(variant) {
    case 'transfer':
      return (
        <div>
          <TransactionModal
            acceptOffer={acceptOffer}
            acceptPurchase={acceptPurchase}
            accounts={accounts}
            data={data}
            declineOffer={declineOffer}
            index={index}
            handleChange={handleChange}
            loading={loading}
            transferAccount={transferAccount}
            withdrawOffer={withdrawOffer}
          />
          <TransactionCard
            avatar={
              data.notification.transfer?.initiatedBy === data.notification.sender._id          
                ? data.notification.sender.avatar
                : data.notification.receiver.avatar
            }
            data={data}
            index={index}
          />
        </div>
      )
    case 'transferAccepted':
      return (
        <TransactionAcceptCard
          userId={userId}
          avatar={
            data.notification.transfer.initiatedBy === data.notification.sender._id          
              ? data.notification.receiver.avatar
              : data.notification.sender.avatar
          }
          data={data}
          index={index}
          dismissed={dismissed}
          dismissNotification={dismissNotification}
        />
      )
      case 'transferDeclined':
        return (
          <TransactionDeclineCard
            userId={userId}
            avatar={
              data.notification.transfer.initiatedBy === data.notification.sender._id          
                ? data.notification.receiver.avatar
                : data.notification.sender.avatar
            }
            data={data}
            index={index}
            dismissed={dismissed}
            dismissNotification={dismissNotification}
          />
        )
    case 'relationship':
      return (
        <div>
          <FriendModal
            data={data}
            index={index}
            dismissed={dismissed}
            dismissNotification={dismissNotification}
          />
          <FriendCard
            userId={userId}
            avatar={
              data.notification.sender._id === userId
                  ? data.notification.sender.avatar 
                  : data.notification.receiver.avatar
            }
            data={data}
            index={index}
            dismissed={dismissed}
          />
        </div>
      )
    case 'relationshipAccepted':
      return (
        <FriendAcceptCard
          userId={userId}
          avatar={
            data.notification.sender._id === userId
                ? data.notification.sender.avatar 
                : data.notification.receiver.avatar
          }
          data={data}
          dismissed={dismissed}
          dismissNotification={dismissNotification}
        />
      )
    default:
      return (
        <PaymentCard
          avatar={data.notification.receiver.avatar}
          data={data}
        />
      )
  }
}