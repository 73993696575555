import React from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'

export default function Step2(props) {
  const { loan, sender, setSender, receiver, setReceiver, friends, disabledSenderReceiver, transactionView, paymentMethods, changeStep, handleChange } = props

  return (
    <Form onSubmit={e => changeStep(e, 3)}>
      <h2 className={'subtitle'}>Transaction Information</h2>
      <Form.Group className={'mt-4'} controlId={'method'}>
        <Form.Label>{ transactionView === 'borrow' ? 'Deposit Account:' : 'Funding Source:' }</Form.Label>
        <Form.Control
          required
          as={'select'}
          className={'white'}
          name={'method'}
          placeholder={'Select Account'}
          value={loan['method']}
          onChange={handleChange}
        >
          <option value={''}>--</option>
          {
            paymentMethods.length > 0 && paymentMethods.map((pm, i) => {
              return <option key={`pm-${i}`} value={i}>{pm.bankName ? `${pm.bankName} ` : ''} {pm.nickname} {pm.identifier ? `...(${pm.identifier})` : ''}</option>
            })
          }
        </Form.Control>
      </Form.Group>
      <Form.Group className={'mt-4'} controlId={'sender'}>
        <Form.Label>{ transactionView === 'borrow' ? 'Request from:' : 'Send to:' }</Form.Label>
        <Form.Control
          required
          disabled={disabledSenderReceiver}
          as={'select'}
          className={'white'}
          name={'sender'}
          value={transactionView === 'borrow' ? sender : receiver}
          placeholder={ transactionView === 'borrow' ? 'Select Friend to Borrow From' : 'Select Friend to Send To' }
          onChange={transactionView === 'borrow' ? e => setSender(e.target.value) : e => setReceiver(e.target.value) }
        >
          <option value={''}>--</option>
          {friends.length && friends.map((friend) => {
            return <option key={`friend-${friend.user._id}`} value={friend.user._id}>{friend.user.first_name} {friend.user.last_name}</option>
          })}
        </Form.Control>
      </Form.Group>
      <Form.Group className={'mt-4'} controlId={'notes'}>
        <Form.Label>Notes:</Form.Label>
        <Form.Control
          required
          className={'white'}
          as={'textarea'}
          rows={3}
          name={'notes'}
          value={loan['notes']}
          placeholder={'What\'s This For?'}
          onChange={handleChange}
        />
      </Form.Group>
      <Row>
        <Col xs={'12'} className={'mt-4'}>
          <Button
            type={'submit'}
            variant={'secondary'}
            className={'w-100'}
            size={'lg'}
          >
            Continue
          </Button>
        </Col>
        <Col xs={'12'} className={'mt-4'}>
          <Button
            onClick={e => changeStep(e, 1)}
            variant={'light'}
            className={'w-100'}
            size={'lg'}
          >
            Previous
          </Button>
        </Col>
      </Row>
    </Form>
  )
}