import React from 'react'
import Footer from '../../components/Footer'

export default function Content(props) {
  const { children, page } = props

  return (
    <div className={'w-100 h-100'}>
      <div className={'content'}>
        {children}
      </div>
      <Footer selected={page} />
    </div>
  )
}