import React, { useState } from 'react'
import { Drawer } from 'baseui/drawer'
import FriendPanel from './../FriendPanel'

export default function FriendsAdd({ id, userId }) {
  const [ isOpen, setIsOpen ] = useState(true)

  const handleClose = () => {
    setIsOpen(false)
  }

  return (
    <Drawer
      anchor={'bottom'}
      isOpen={isOpen}
      onClose={handleClose}
      showBackdrop
      overrides={{
        Root: {
          style: {
            zIndex: 2
          }
        },
        DrawerContainer: {
          style: {
            backgroundColor: '#64b1ff !important',
            height: '100vh'
          }
        },
        DrawerBody: {
          style: {
            margin: 0
          }
        },
        Close: {
          style: {
            fill: 'white',
            top: '0',
            left: '0',
            height: '50px',
            width: '50px',
            position: 'fixed'
          }
        }
      }}
    >
      <FriendPanel
        id={id}
        userId={userId}
        handleClose={handleClose}
      />
    </Drawer>
  )
}