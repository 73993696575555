import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { Alert, Button, Card, Col, Container, Form, Modal, Row, Spinner } from 'react-bootstrap'
import PageTitle from '../../components/PageTitle'
import User from '../../helpers/User'
import './styles.scss'

export default function NewFeatures() {
  const userData = new User().getData()
  const userId = userData.user.id
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [selectedFeatures, setSelectedFeatures] = useState([])
  const [featureRecord, setFeatureRecord] = useState([])
  const [featuresList, setFeatureList] = useState([
		{
		  'title' : 'Unlimited Loans',
		  'desc' : 'Free users have a limit (3) on the number of loans they can lend and borrow. Paid premium subscription users will have an unlimited number of loans they can lend and borrow.',
		  'checked': 0
		},
		{
		  'title' : 'Reduced Transaction Fee',
		  'desc' : 'Paid premium subscription users will have lower transaction fees',
		  'checked': 0
		},
		{
		  'title' : 'Ledger Payments',
		  'desc' : 'You can spend as much as you are able with Pari. There is no transaction limit on your app purchases.',
		  'checked': 0
		},
		{
		  'title' : 'Push Reminders',
		  'desc' : 'Lenders will be able to manually send payment reminders to users who have upcoming payments in addition to the default automatic reminders.',
		  'checked': 0
		},
		{
		  'title' : 'Loan Forgiveness',
		  'desc' : 'For a small fee, paid premium subscription users will be allowed to request/approve forgiveness of a loan and discontinue upcoming payments and clear the balance of the loan.',
		  'checked': 0
		},
		{
		  'title' : 'Refinancing',
		  'desc' : 'Paid premium subscription users will be able to refinance existing loans and change the terms of the remaining balance of the loan. (Payment amount, term length, interest rate, and loan fees)',
		  'checked': 0
		},
		{
		  'title' : 'Lender Protection',
		  'desc' : 'Through a new open-banking feature, all lending users will be notified of high risk financial transactions and behavior of a borrowing user prior to confirming a new loan.',
		  'checked': 0
		},
		{
		  'title' : 'Default Protection',
		  'desc' : 'All users will be allowed to purchase loan default insurance on qualifying transactions. This new feature will protect lenders from the possibility of a loan defaulting.',
		  'checked': 0
		},
  ])
  
  
  const [successMessage, setsuccessMessage] = useState(0)
	useEffect(() => {
		setLoading(true)

		const fetchData = async() => {
			const features = await axios.get(`api/user/${userId}/features`)
			var selectedValues = [];
			for (var i=0;i<features.data.length;i++) {
				selectedValues.push(features.data[i].feature);
			}
			setSelectedFeatures(selectedValues);
			var output=[];
			for (var i=0; i<featuresList.length;i++) {
			var checked = selectedValues.includes(featuresList[i].title);

			console.log(checked);
			var title = featuresList[i].title;
			var tempItem= ( <Card className={'mt-4'} key={i}>
			<Card.Header>{featuresList[i].title}</Card.Header>
				  <Card.Body>
				   <Form.Check 
						type="switch"
						id={'custom-'+i}
						label=""
						defaultChecked={checked}
						onChange={e => featureToggelChange(e)}
					  />
					<br/>
					<Card.Text>
					  {featuresList[i].desc}
					</Card.Text>
					
				  </Card.Body>
				</Card>);
				output[i] = tempItem;
			}
			console.log(output);
			setFeatureRecord(output)	
			
		}
		fetchData()

	}, [ userId ])
	
	
	
	
	const featureToggelChange = (e) => {
	  const currentobject = e.target.id.split("-");

	  let selectedObject = featuresList[currentobject[1]];
	  console.log(e.target.checked);
	  var title = selectedObject['title'];

	  if (e.target.checked==true) {
		selectedFeatures.push(title);
		setSelectedFeatures(selectedFeatures);
	  } else {
		var tempData = selectedFeatures;
		for (var i=0;i<tempData.length;i++) {
			if (tempData[i]===title) {
				tempData.splice(i, 1)
			}
		}
		setSelectedFeatures(tempData);
	
	  }
	 
	}
	
	const saveNewFeatures = () => {
		const body = {
		   id: userId,
		   features :selectedFeatures
		  
		};
		
		axios.post(`/api/user/newfeatures/`+userId, body)
		  .then(res => {
			setsuccessMessage(1);
		  })
		  .catch(error => {
			console.log('Error : ', error);
		  });
	};

  return (
    <div>
      <PageTitle
        title={'Settings / New Features'}
        nav={'/settings'}
      />
	   <div className={'app-body app-body--page'}>
        <Container className={' pt-3 pb-3'}>
		
          <Col
            lg={'6'}
            className={'mx-auto p-0'}
          >
		{ successMessage ==1 ? <Alert key={'success'} variant={'success'}>
			New Feature has been saved.
        </Alert> : null }
  
		<Alert key={'info'} variant={'info'}>
			Turn on the toggle for the features you would like to use and you may be eligible for early access.
        </Alert>
		{featureRecord}
	   
		<div className={'mt-4'}>
			<Button
			  variant={'secondary'}
			  className={'w-100'}
			  size={'lg'}
			  onClick= {saveNewFeatures}
			>
			  Update Feature
			</Button>
		</div>
		
		</Col>
		</Container>
		</div>
	</div>  
  )
}