import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Button, Col, Container, Image, Modal, Row, Spinner, Card } from 'react-bootstrap'
import { Image as Cloudinary } from 'cloudinary-react'
import blankAvatar from '../../assets/blank-avatar.png'
import User from '../../helpers/User'
import moment from 'moment'
import ProgressBar from 'react-bootstrap/ProgressBar';

require('./styles.scss')

export default function Lendstats() {
	const userData = new User().getData()
	const id = userData.user.id
	const [statData, setStatData] = useState([])
	const [ loading, setLoading ] = useState(false)
	useEffect(() => {
		const controller = new AbortController()
		
		const fetchData = async() => {
			const lendStatPromise = await axios.get(`/api/user/${id}/lend/stats`) 
			var tempData= [] ;
			for (var i=0;i<lendStatPromise.data.length;i++) {
				var lendData = lendStatPromise.data[i];
				let remaining = lendData['term']-lendData['total_done'];
				let remaining_percentage = lendData['total_done']/lendData['term'];
				remaining_percentage = remaining_percentage*100;
				tempData.push({
					"done_percentage":remaining_percentage.toFixed(2),
					"remaining":remaining,
					"term":lendData['term'],
					"amount":lendData['amount'],
					"total_done":lendData['total_done'],
					"receiver_name":lendData['receiver']['name'],
				});
			}
			setStatData(tempData);

		}
    
    if(id)
      fetchData()

		return () => {
			controller.abort()
    }
  }, [ id ])
	const now = 60;
  return (
		<Container className={' pt-3 pb-3 '}>
		{statData.map((stat,index) =>
		<Col
            lg={'6'}
            className={'mx-auto p-0'}
			key = {index}
          >
		  <Card className={'mt-4'} >
				<Card.Body>
					<ProgressBar now={stat['done_percentage']}  variant="custom" />
					<Row>
						<Col lg={'6'} >
							<b>{stat['receiver_name']}</b>
							<br/>
							<b>{stat['total_done']}</b> Paid
						</Col>
						<Col lg={'6'} style={{textAlign: 'right'}}>
							Current Balance : <b>${stat['amount']}</b> 
							<br/>
							<b>{stat['remaining']}</b> Payment Remaining
						</Col>
					</Row>
				</Card.Body>
		  </Card>
		</Col> 
		)}
		</Container>
	
  )
}