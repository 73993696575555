import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button, Col, Container, Form, Image, Nav, Row, Spinner } from 'react-bootstrap'
import axios from 'axios'
import DatePicker from 'react-datepicker'
import SelectUSState from 'react-select-us-states'
import Swal from 'sweetalert2'
import User from '../../helpers/User'
import moment from 'moment'
import './styles.scss'
import 'react-datepicker/dist/react-datepicker.css'
import close from '../../assets/close-white.png'

export default function SignUp() {
  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    user: '',
    pass: '',
    rePass: '',
    address: '',
    city: '',
    state: 'AL',
    zip: '',
    dob: '1980-01-01',
    idNum: '',
    ccNum: '',
    ccExp: '01-2026',
    cvv: '',
    ssn: '',
    acceptedTerms: false,
  })
  const [loading, setLoading] = useState(false)
  const [acceptedTerms, setAcceptedTerms] = useState(false)
  const [step, setStep] = useState(1)
  const history = useHistory()
  const regex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})') //eslint-disable-line
    
  const changeForm = (name, value) => {
		let newForm = Object.assign({}, form)
		newForm[name] = value
		
		setForm(newForm)
  }

  const changeStep = (e, number) => {
    e.preventDefault()

    setStep(number)
  }

  const validateCard = async(e) => {
    e.preventDefault()
    try {
      const response = await axios.post('/api/validate-card', {
        pan: form.ccNum,
        expDate: form.ccExp.split('-')[0] + form.ccExp.split('-')[1].slice(2),
        cvv2: form.cvv,
      });
      if (response.data.code === '200') {
        changeStep(e, 4)
      } else {
        await Swal.fire({
        icon: 'error',
        title: 'Invalid Card Details!',
        html: 'Your card details are incorrect. Please check and try again.'
      })
      }
    }
    catch(err) {
      await Swal.fire({
        icon: 'error',
        title: 'Invalid Card Details!',
        html: 'Your card details are incorrect. Please check and try again.'
      })
    }
  }

  const handleSubmit = async(e) => {
    e.preventDefault()

    setLoading(true)

    const regexTest = regex.test(form['pass'])
    const confirmationTest = form['pass'] === form['rePass']
 
    try {
      if(!regexTest || !confirmationTest){
        const error = {response: {data: regexTest ? 'Password and confirmation must be the same' : 'Password needs to have at least one lowercase, uppercase, number, and symbol in an 8+ character length'}}
        throw(error)
      }
      
      const response = await axios.post('/api/sign-up', { username: form['user'], password: form['pass'], data: form })
      await Swal.fire({
        icon: 'success',
        title: 'Success!',
        html: 'Your registration was sent! Please verify our email and confirm your account to log in.'
      })
  
      history.push('/login')
    }catch(err){
      await Swal.fire({
        icon: 'error',
        title: 'Error!',
        html: err
      })

      new User().removeData()
      setLoading(false)
    }
  }
  
  useEffect(() => {
    changeForm('acceptedTerms', acceptedTerms)
  }, [acceptedTerms])

  return (
    <div className={'app-blue'}>
      <Container className={'signup'}>
        <header className={'pl-4 pt-2 text-left w-100 container'}>
          <Nav.Link as={Link} to={'/login'} className={'p-1'}>
            <Image src={close} className={'signup__close'} />
          </Nav.Link>
        </header>
        <Col sm={'6'} className={'mx-auto pt-3'}>
          <div>
            <div className={'signup__header'}>
              <h1 className={'title'}>Sign up</h1>
              <p className={'mt-4'}><small>Our payment processing system requires the requested information for identification purposes. Pari is currently available for US users over 18 with a valid government-issued ID.</small></p>
            </div>
            <div className={'signup__form'}>
              <div className={'signup__stepper'}>
                {step === 1 &&
                  <Form onSubmit={e => changeStep(e, 2)}>
                    <h2 className={'subtitle'}>Personal Information</h2>
                    <div className={'mt-4'}>
                      <small>First Name</small>
                      <Form.Control
                        type={'text'}
                        required={true}
                        placeholder={'First Name'}
                        className={'white'}
                        value={form['firstName']}
                        onChange={(e) => changeForm('firstName', e.target.value)}
                      >
                      </Form.Control>
                    </div>
                    <div className={'mt-2'}>
                      <small>Last Name</small>
                      <Form.Control
                        type={'text'}
                        required={true}
                        placeholder={'Last Name'}
                        className={'white'}
                        value={form['lastName']}
                        onChange={(e) => changeForm('lastName', e.target.value)}
                      >
                      </Form.Control>
                    </div>
                    <div className={'mt-2'}>
                      <small>Date of Birth</small>
                      <Form.Control
                        type={'date'}
                        required={true}
                        className={'white'}
                        value={form['dob']}
                        onChange={(e) => changeForm('dob', e.target.value)}
                      >
                      </Form.Control>
                    </div>
                    <div className={'mt-2'}>
                      <small>Identification Number (Valid US Driver License or ID)</small>
                      <Form.Control
                        type={'text'}
                        required={true}
                        placeholder={'Identification Number'}
                        className={'white'}
                        value={form['idNum']}
                        onChange={(e) => changeForm('idNum', e.target.value)}
                      >
                      </Form.Control>
                    </div>
                    <div className={'mt-4'}>
                      <Button
                        type={'submit'}
                        variant={'secondary'}
                        className={'w-100'}
                        size={'lg'}
                      >
                        Continue
                      </Button>
                    </div>
                  </Form>
                }
                {step === 2 &&
                  <Form onSubmit={e => changeStep(e, 3)}>
                    <h2 className={'subtitle'}>Address Information</h2>
                    <div className={'mt-4'}>
                      <small>Address</small>
                      <Form.Control
                        type={'text'}
                        required={true}
                        placeholder={'Address'}
                        className={'white'}
                        value={form['address']}
                        onChange={(e) => changeForm('address', e.target.value)}
                      >
                      </Form.Control>
                    </div>
                    <div className={'mt-2'}>
                      <small>City</small>
                      <Form.Control
                        type={'text'}
                        required={true}
                        placeholder={'City'}
                        className={'white'}
                        value={form['city']}
                        onChange={(e) => changeForm('city', e.target.value)}
                      >
                      </Form.Control>
                    </div>
                    <div className={'mt-2'}>
                      <small>State</small>
                      <SelectUSState
                        className={'form-control white'}
                        value={form['state']}
                        onChange={(state) => changeForm('state', state)}
                      >
                      </SelectUSState>
                    </div>
                    <div className={'mt-2'}>
                      <small>ZIP Code</small>
                      <Form.Control
                        type={'text'}
                        required={true}
                        placeholder={'ZIP Code'}
                        className={'white'}
                        value={form['zip']}
                        onChange={(e) => changeForm('zip', e.target.value)}
                      >
                      </Form.Control>
                    </div>
                    <Row>
                      <Col xs={'6'} className={'mt-4'}>
                        <Button
                          onClick={e => changeStep(e, 1)}
                          variant={'light'}
                          className={'w-100'}
                          size={'lg'}
                        >
                          Previous
                        </Button>
                      </Col>
                      <Col xs={'6'} className={'mt-4'}>
                        <Button
                          type={'submit'}
                          variant={'secondary'}
                          className={'w-100'}
                          size={'lg'}
                        >
                          Continue
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                }
                {step === 3 &&
                  <Form onSubmit={e => validateCard(e)}>
                  <h2 className={'subtitle'}>Card Information</h2>
                  <div className={'mt-4'}>
                    <small>Card Number</small>
                    <Form.Control
                      type={'text'}
                      required={true}
                      minLength={15}
                      maxLength={16}
                      placeholder={'Card Number'}
                      className={'white'}
                      value={form['ccNum']}
                      onChange={(e) => changeForm('ccNum', e.target.value)}
                    >
                    </Form.Control>
                  </div>
                  <div className={'mt-2'}>
                    <small>Card Expiration</small>
                    <DatePicker
                      type={'date'}
                      dateFormat="mm/yyyy"
                      required={true}
                      placeholder={'Expiration'}
                      className={'white'}
                      value={form['ccExp']}
                      onChange={(date) => changeForm('ccExp', moment(date).format('MM/YYYY'))}
                    >
                    </DatePicker>
                  </div>
                  <div className={'mt-2'}>
                    <small>Card CVV</small>
                    <Form.Control
                      type={'text'}
                      required={true}
                      minLength={3}
                      maxLength={4}
                      placeholder={'CVV'}
                      className={'white'}
                      value={form['cvv']}
                      onChange={(e) => changeForm('cvv', e.target.value)}
                    >
                    </Form.Control>
                  </div>
                  <Row>
                    <Col xs={'6'} className={'mt-4'}>
                      <Button
                        onClick={e => changeStep(e, 2)}
                        variant={'light'}
                        className={'w-100'}
                        size={'lg'}
                      >
                        Previous
                      </Button>
                    </Col>
                    <Col xs={'6'} className={'mt-4'}>
                      <Button
                        type={'submit'}
                        variant={'secondary'}
                        className={'w-100'}
                        size={'lg'}
                      >
                        Continue
                      </Button>
                    </Col>
                  </Row>
                </Form>
                }
                {step === 4 &&
                  <Form onSubmit={e => handleSubmit(e)}>
                    <h2 className={'subtitle'}>Account Information</h2>
                    <div className={'mt-4'}>
                      <small>Email Address</small>
                      <Form.Control
                        type={'email'}
                        required={true}
                        placeholder={'Email Address'}
                        className={'white'}
                        value={form['user']}
                        onChange={(e) => changeForm('user', e.target.value)}
                      >
                      </Form.Control>
                    </div>
                    <div className={'mt-2'}>
                      <small>Password</small>
                      <Form.Control
                        type={'password'}
                        required={true}
                        placeholder={'Password'}
                        className={'white'}
                        value={form['pass']}
                        onChange={(e) => changeForm('pass', e.target.value)}
                      >
                      </Form.Control>
                    </div>
                    <div className={'mt-2'}>
                      <small>Confirm Password</small>
                      <Form.Control
                        type={'password'}
                        required={true}
                        placeholder={'Confirm Password'}
                        className={'white'}
                        value={form['rePass']}
                        onChange={(e) => changeForm('rePass', e.target.value)}
                      >
                      </Form.Control>
                    </div>
                    <Row>
                      <Form.Check type="checkbox" label={<span className="d-block my-4">By checking this box, I acknowledge that I agree to Pari's <a href="/terms" target="_blank">Terms and Conditions</a>.</span>} onChange={() => setAcceptedTerms(!acceptedTerms)} />
                    </Row>
                    <Row>
                      <Col xs={'6'}>
                        <Button
                          onClick={e => changeStep(e, 3)}
                          variant={'light'}
                          className={'w-100'}
                          size={'lg'}
                          disabled={loading}
                        >
                          Previous
                        </Button>
                      </Col>
                      <Col xs={'6'}>
                        <Button
                          type={'submit'}
                          variant={'secondary'}
                          className={'w-100'}
                          size={'lg'}
                          disabled={loading || !acceptedTerms}
                        >
                          {loading ?
                              <Spinner
                                as={'span'}
                                animation={'border'}
                              />
                            :
                              <span>Submit</span>
                          }
                        </Button>
                      </Col>
                    </Row>
                  </Form>}
              </div>
            </div>
          </div>
          <div className={'pt-5 pb-5'}>
            <span>Have an account? </span>
            <Nav.Link
              as={Link}
              to={'/login'}
              className={'nav-link--alt'}
            >
              <b>Sign In</b>
            </Nav.Link>
          </div>
        </Col>
      </Container>
    </div>
  )
}